import React from 'react';
import { useAddWidgetStore } from 'stores/add-widget-store';
import { useSchemaStore } from 'stores/schema-store';
import { SlotIdEnum } from 'types/schema';

import * as S from './layout-editor-half-column-picker.styled';
import { LayoutEditorSlotPicker } from '../layout-editor-slot-picker';
import { isSlotCovered } from '../utils';

interface LayoutEditorHalfColumnPickerProps {
  onSelectPlacement: (slotIds: SlotIdEnum[]) => void;
}

const PLACEMENT_OPTIONS = [
  SlotIdEnum.Slot1,
  SlotIdEnum.Slot2,
  SlotIdEnum.Slot3,
  SlotIdEnum.Slot4,
  SlotIdEnum.Slot5,
  SlotIdEnum.Slot6,
];

export const LayoutEditorHalfColumnPicker = ({ onSelectPlacement }: LayoutEditorHalfColumnPickerProps) => {
  const schema = useSchemaStore((state) => state.schema);
  const editedWidgetSlotIds = useAddWidgetStore((state) => state.editedWidgetSlotIds);

  return (
    <S.LayoutEditorHalfColumnPicker>
      {PLACEMENT_OPTIONS.map((slotId) => {
        const isCovered = isSlotCovered(schema, [slotId], editedWidgetSlotIds);

        return (
          <LayoutEditorSlotPicker
            disabled={isCovered}
            key={slotId}
            onSelectPlacement={() => onSelectPlacement([slotId])}
          />
        );
      })}
    </S.LayoutEditorHalfColumnPicker>
  );
};
