import React from 'react';

import * as S from './widget-weather-current-details.styled';

interface WidgetWeatherCurrentDetailsProps {
  icon: JSX.Element;
  data: string;
  label: string;
}

export const WidgetWeatherCurrentDetails = ({ data, label, icon }: WidgetWeatherCurrentDetailsProps) => (
  <S.Container>
    {icon}
    <S.TextContainer>
      <div>{data}</div>
      <div>{label}</div>
    </S.TextContainer>
  </S.Container>
);
