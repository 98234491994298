import React from 'react';

import * as S from './button.styled';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
}

export const Button = ({ children, onClick, className, type, isLoading }: ButtonProps) => (
  <S.Button type={type} className={className} onClick={onClick} disabled={isLoading}>
    {isLoading ? 'Loading...' : children}
  </S.Button>
);

export const ModalButton = (props: ButtonProps) => <S.ModalButton {...props} />;
