import { Document, Page } from 'react-pdf';
import styled from 'styled-components';
import { formatViewport } from 'utils/get-pixels-to-viewport';

export const PdfsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const NoPdfsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const PdfsList = styled.div<{ $activeIndex: number }>`
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: ${({ $activeIndex }) => `translateX(-${$activeIndex * 100}%)`};
  transition: transform 0.5s ease-in-out;
  color: ${({ theme }) => theme.text.primary};
`;

export const PdfWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
`;

export const NoPdfTitle = styled.div`
  font-size: ${formatViewport(16)};
`;

export const PdfDocument = styled(Document)`
  height: 100% !important;

  & > .react-pdf__Page {
    width: 100% !important;
    height: 100% !important;
  }

  & .react-pdf__Page__canvas {
    display: flex !important;
    height: 100% !important;
    justify-content: center !important;
    width: 100% !important;

    canvas {
      width: 100%;
      height: 100%;
    }
  }
`;

export const PdfPage = styled(Page)``;
