import styled from 'styled-components';

export const SelectWrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};
`;

export const Select = styled.select<{ $error: boolean }>`
  padding: 8px;
  border: 1px solid ${(props) => (props.$error ? props.theme.error : props.theme.border.form)};
  background-color: ${(props) => props.theme.background.form};
  color: ${(props) => props.theme.text.primary};
  border-radius: 4px;
  height: 35px;
`;

export const Error = styled.span`
  color: ${(props) => props.theme.error};
  font-size: 12px;
`;
