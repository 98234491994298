import { WidgetTypeEnum } from 'types/schema';

export const getWidgetLabel = (widget: WidgetTypeEnum) => {
  switch (widget) {
    case WidgetTypeEnum.Weather:
      return 'Weather';
    case WidgetTypeEnum.RotationGallery:
      return 'Image/PDF Rotation Gallery';
    case WidgetTypeEnum.Announcements:
      return 'Announcements';
    case WidgetTypeEnum.NeighborhoodPerks:
      return 'Neighborhood Perks';
    case WidgetTypeEnum.AutomatedEventFlyerRotation:
      return 'Automated Event Flyer Rotation';
    case WidgetTypeEnum.Calendar:
      return 'Calendar';
    // case WidgetTypeEnum.Transportation:
    // return 'Transportation';
    default:
      return null;
  }
};
