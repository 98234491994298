import { DEFAULT_TIMEZONE } from 'contants/time';
import React, { useEffect, useState } from 'react';
import { HashLoader } from 'react-spinners';
import urljoin from 'url-join';
import { weatherIcons } from 'utils/assets';

import { useGetBuilding } from 'api/get-building';
import { useGetWeather } from 'api/get-weather';

import * as S from './widget-weather.styled';
import { WidgetDropdown } from '../widget-dropdown';
import { getAMPM, getCurrentTime, getFullDay, getTimezonedHoursAndMinutes } from './utils';
import { WidgetWeatherCurrentDetails } from './widget-weather-current-details/widget-weather-current-details';
import { WidgetWeatherHourlyDetails } from './widget-weather-hourly-details/widget-weather-hourly-details';

interface WidgetWeatherProps {
  widgetId: string;
}

export const WidgetWeather = ({ widgetId }: WidgetWeatherProps) => {
  const { data: weather, isError, isLoading } = useGetWeather();
  const { data } = useGetBuilding();
  const [currentDate, setCurrentDate] = useState(new Date());
  const { data: building } = useGetBuilding();

  // Note: change current date every second
  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderWeather = () => {
    if (isLoading || !weather) {
      return (
        <S.SpinnerContainer>
          <HashLoader />
        </S.SpinnerContainer>
      );
    }

    if (isError) {
      return <div>Error occured when checking weather</div>;
    }

    return (
      <>
        <S.TopContainer>
          <S.MainContainer>
            <S.TemperatureTime>
              <S.Temperature>
                <S.TemperatureIconWrapper>
                  <S.MainIcon
                    src={urljoin('http://openweathermap.org/img/wn/', `${weather!.current?.iconId}@4x.png`)}
                  />
                  <S.Degree>{Math.round(weather.current.temperature)}&deg;F</S.Degree>
                </S.TemperatureIconWrapper>
                <S.Label>{data?.address}</S.Label>
              </S.Temperature>
              <S.Time>
                <S.ClockContainer>
                  <S.ClockText>{getCurrentTime(currentDate, building?.timezone ?? DEFAULT_TIMEZONE)}</S.ClockText>
                  <S.ClockSuffix>{getAMPM(currentDate, building?.timezone ?? DEFAULT_TIMEZONE)}</S.ClockSuffix>
                </S.ClockContainer>
                <S.Label>{getFullDay(currentDate, building?.timezone ?? DEFAULT_TIMEZONE)}</S.Label>
              </S.Time>
            </S.TemperatureTime>
          </S.MainContainer>
          <S.CurrentDetailsContainer>
            <WidgetWeatherCurrentDetails
              icon={<weatherIcons.Wind />}
              data={`${weather.current.windSpeed} mph`}
              label="Wind"
            />
            <WidgetWeatherCurrentDetails
              icon={<weatherIcons.Rain />}
              data={`${weather.current.chanceOfRain * 100}%`}
              label="Chance of rain"
            />
            <WidgetWeatherCurrentDetails
              icon={<weatherIcons.Temp />}
              data={`${weather.current.pressure} mbar`}
              label="Pressure"
            />
            <WidgetWeatherCurrentDetails
              icon={<weatherIcons.Humidity />}
              data={`${weather.current.humidity}%`}
              label="Humidity"
            />
          </S.CurrentDetailsContainer>
        </S.TopContainer>
        <S.Splitter />
        <S.BottomContainer>
          <WidgetWeatherHourlyDetails
            label="Now"
            icon={weather!.current.iconId}
            temp={weather!.current.temperature}
            chanceOfRain={weather!.current.chanceOfRain}
          />
          {weather?.hourly.map((info) => (
            <WidgetWeatherHourlyDetails
              key={info.date}
              label={getTimezonedHoursAndMinutes(info.date, DEFAULT_TIMEZONE, 'h:mm a')}
              icon={info.iconId}
              temp={info.temperature}
              chanceOfRain={info.chanceOfRain}
            />
          ))}
        </S.BottomContainer>
      </>
    );
  };

  return (
    <>
      <S.Wrapper>{renderWeather()}</S.Wrapper>
      <WidgetDropdown widgetId={widgetId} />
    </>
  );
};
