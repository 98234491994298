import { Plus } from '@phosphor-icons/react';
import { useMemo, useState } from 'react';
import { useAddWidgetStore } from 'stores/add-widget-store';
import { useSchemaStore } from 'stores/schema-store';
import { Schema } from 'types/schema';
import { getLocationIdFromUrl } from 'utils/get-location-id-from-url';

import { useSaveSchema } from 'api/save-schema';

import { Select } from 'components/base/select';

import * as S from './footer.styled';
import { Template, templateOptions, templates } from './templates';

export const Footer = () => {
  const setIsOpen = useAddWidgetStore((state) => state.setIsOpen);
  const schema = useSchemaStore((state) => state.schema);
  const setSchema = useSchemaStore((state) => state.setSchema);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | ''>('');
  const { mutateAsync: saveSchema, isLoading } = useSaveSchema();

  const [copiedSchema, setCopiedSchema] = useState<Schema>(schema);

  const allTemplatesOptions = useMemo(() => [...templateOptions, { label: 'Select template', value: '' }], []);

  const onSaveSchema = async () => {
    const locationId = getLocationIdFromUrl(window.location.href);
    setSelectedTemplate('');
    await saveSchema({ locationId, schema });
  };

  const onTemplateChange = (template: Template) => {
    if (!templates[template]) {
      return;
    }

    setSelectedTemplate(template);
    setCopiedSchema(schema);
    setSchema(templates[template]);
  };

  const onDiscardTemplate = () => {
    setSchema(copiedSchema);
    setSelectedTemplate('');
  };

  const onApplyTemplate = () => {
    setSelectedTemplate('');
    setCopiedSchema(schema);
  };

  const handleSelectTemplate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTemplate = e.target.value;

    if (selectedTemplate === '') {
      onDiscardTemplate();
      return;
    }

    setSelectedTemplate(selectedTemplate as Template);
    onTemplateChange(selectedTemplate as Template);
  };

  return (
    <S.Footer>
      <S.TemplateSelectWrapper>
        <Select
          value={selectedTemplate}
          onChange={handleSelectTemplate}
          label="Templates"
          options={allTemplatesOptions}
        />
        {selectedTemplate ? (
          <>
            <S.DiscardTemplateButton onClick={onDiscardTemplate}>Discard</S.DiscardTemplateButton>
            <S.ApplyTemplateButton onClick={onApplyTemplate}>Apply</S.ApplyTemplateButton>
          </>
        ) : null}
      </S.TemplateSelectWrapper>
      <S.AddWidgetButton onClick={() => setIsOpen(true)}>
        <Plus />
        Add New Widget
      </S.AddWidgetButton>
      <S.SaveAndPublishButton onClick={onSaveSchema} isLoading={isLoading}>
        Save & Publish
      </S.SaveAndPublishButton>
    </S.Footer>
  );
};
