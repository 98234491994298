import React from 'react';

import * as S from './card.styled';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

export const Card = ({ children, className, ...props }: CardProps) => (
  <S.CardWrapper className={className} {...props}>
    {children}
  </S.CardWrapper>
);
