import { Gear } from '@phosphor-icons/react';
import { useState } from 'react';
import { useIsEditMode } from 'stores/edit-mode-enum';
import { useSettingsStore } from 'stores/settings-store';

import * as S from './header.styled';
import { SettingsModal } from '../modals/settings-modal';

export const Header = () => {
  const settings = useSettingsStore((state) => state.settings);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isEditMode] = useIsEditMode();

  const renderLogo = () => {
    if (settings.isDarkMode && settings.customLogoDarkUrl) {
      return (
        <S.CustomLogoWrapper>
          <S.CustomLogo src={settings.customLogoDarkUrl} />
        </S.CustomLogoWrapper>
      );
    }

    if (settings.customLogoUrl && !settings.isDarkMode) {
      return (
        <S.CustomLogoWrapper>
          <S.CustomLogo src={settings.customLogoUrl} />
        </S.CustomLogoWrapper>
      );
    }

    return <div />;
  };

  return (
    <>
      <S.Header>
        <S.SettingsButton $hide={!isEditMode} onClick={() => setIsSettingsModalOpen(true)}>
          Settings
          <Gear size={16} />
        </S.SettingsButton>
        {renderLogo()}
        <S.LogoWrapper $hide={settings.isLogoHidden}>
          {settings.isDarkMode ? <S.LogoDarkMode /> : <S.Logo />}
        </S.LogoWrapper>
      </S.Header>
      <SettingsModal isOpen={isSettingsModalOpen} onClose={() => setIsSettingsModalOpen(false)} />
    </>
  );
};
