import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const formatDate = (date: Date | string, formatStr: string, timezone?: string) => {
  if (typeof date === 'string') {
    return format(timezone ? toZonedTime(parseISO(date), timezone) : parseISO(date), formatStr);
  }
  return format(timezone ? toZonedTime(date, timezone) : date, formatStr);
};

export const formatDateToDayMonthYear = (date: Date | string, timezone?: string) =>
  formatDate(date, 'MM/dd/yyyy', timezone);

export const formatDateToYearMonthDayDate = (date: Date | string, timezone?: string) =>
  formatDate(date, 'yyyy-dd-MM', timezone);
