import styled from 'styled-components';
import { WidgetSizeEnum } from 'types/schema';
import { formatViewport } from 'utils/get-pixels-to-viewport';

import { getGridTemplate } from './utils';

export const WidgetWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3%;
  gap: 2%;
`;

export const NeighbourhoodPerksWrapper = styled.div<{ $widgetSize: WidgetSizeEnum }>`
  position: relative;
  width: 100%;
  height: 100%;
  gap: 2%;
  display: grid;
  ${(props) => getGridTemplate(props.$widgetSize)};
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const NoNeighbourhoodPerksWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SlideIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${formatViewport(10)};
  gap: ${formatViewport(10)};
`;

export const SlideIndicator = styled.div<{ $active: boolean }>`
  width: ${formatViewport(12)};
  height: ${formatViewport(12)};
  margin: 0;
  border-radius: 50%;
  background-color: ${({ $active, theme }) => ($active ? theme.text.primary : theme.text.secondary)};
  transition: background-color 0.3s ease;
`;
