import styled from 'styled-components';
import { logo } from 'utils/assets';

import { Button } from '../base/button';

export const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;

export const SettingsButton = styled(Button)<{ $hide: boolean }>`
  justify-self: start;
  height: fit-content;
  align-self: center;
  align-items: center;
  visibility: ${({ $hide }) => ($hide ? 'hidden' : 'visible')};
`;

export const CustomLogoWrapper = styled.div`
  height: 7vh;
  aspect-ratio: 3/1;
  justify-self: center;
`;

export const CustomLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const LogoWrapper = styled.div<{ $hide?: boolean }>`
  height: 7vh;
  aspect-ratio: 3/1;
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
  justify-self: end;
`;

export const Logo = styled(logo.Regular)`
  height: 100%;
  width: auto;
`;

export const LogoDarkMode = styled(logo.White)`
  height: 100%;
  width: auto;
`;
