import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { useUploadFiles } from 'api/upload-files';

export const useUploadPhoto = (onCallback: (photoUrl: string) => void) => {
  const { mutateAsync: uploadFiles } = useUploadFiles();

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    onDrop: async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }

      const [photo] = acceptedFiles;
      const formData = new FormData();
      formData.append('files', photo);
      const { fileUrls } = await uploadFiles(formData);
      onCallback(fileUrls[0]);
      toast.success('Photo uploaded successfully');
    },
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 10MB
  });

  const isFileTooBig = fileRejections.some((file) => file.errors.some((error) => error.code === 'file-too-large'));

  return { getRootProps, getInputProps, isFileTooBig };
};
