import { Info } from '@phosphor-icons/react';
import React from 'react';

import * as S from './badge.styled';

interface BadgeProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export const Badge = ({ icon = <Info />, children }: BadgeProps) => (
  <S.BadgeWrapper>
    {icon}
    <span>{children}</span>
  </S.BadgeWrapper>
);
