import { addHours, parseISO, set } from 'date-fns';
import { Announcement, WidgetSizeEnum } from 'types/schema';

export const getGridTemplate = (widgetSize: WidgetSizeEnum) => {
  switch (widgetSize) {
    case WidgetSizeEnum.FullColumn:
      return 'grid-template-rows: 1fr; grid-template-columns: 1fr;';
    case WidgetSizeEnum.HalfColumn:
      return 'grid-template-rows: 1fr; grid-template-columns: 1fr';
    case WidgetSizeEnum.HorizontalColumn:
      return 'grid-template-rows: 1fr; grid-template-columns: 1fr 1fr;';
    case WidgetSizeEnum.SquareColumn:
      return 'grid-template-rows: 1fr; grid-template-columns: 1fr 1fr;';
    default:
      return '';
  }
};

export const getSlidesCountBasedOnWidgetSize = (widgetSize: WidgetSizeEnum) => {
  switch (widgetSize) {
    case WidgetSizeEnum.FullColumn:
      return 1;
    case WidgetSizeEnum.HalfColumn:
      return 1;
    case WidgetSizeEnum.HorizontalColumn:
      return 2;
    case WidgetSizeEnum.SquareColumn:
      return 2;
    default:
      return 1;
  }
};

const getStartOfDay = (dateString: string) => {
  const parsedDate = parseISO(dateString);
  const startOfDay = set(parsedDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  return startOfDay;
};

export const filterAnnouncementsByDateAndTime = (announcements: Announcement[], currentDate: Date) =>
  announcements.filter((announcement) => {
    const startDate = addHours(getStartOfDay(announcement.startDate), Number(announcement.startTime));

    if (currentDate < startDate) return false;

    if (announcement.isManuallyRemoved) return true;

    const endDate = addHours(
      getStartOfDay(announcement.startDate),
      Number(announcement.startTime) + Number(announcement.duration),
    );

    return currentDate < endDate;
  });
