import { Pencil } from '@phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useIsEditMode } from 'stores/edit-mode-enum';

import * as S from './widget-dropdown.styled';
import { useManageWidget } from './hooks';

interface WidgetDropdownProps {
  widgetId: string;
  onEdit?: () => void;
}

export const WidgetDropdown = ({ widgetId, onEdit }: WidgetDropdownProps) => {
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const { onChangeSize, onMove, onRemove } = useManageWidget(widgetId, setIsWidgetOpen);
  const [isEditMode] = useIsEditMode();

  const { attributes } = usePopper(buttonRef.current, menuRef.current, {
    placement: 'bottom-end',
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsWidgetOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleEdit = () => {
    setIsWidgetOpen(false);
    onEdit?.();
  };

  if (!isEditMode) {
    return null;
  }

  return (
    <S.DropdownIconWrapper>
      <S.DropdownButton ref={buttonRef} onClick={() => setIsWidgetOpen(!isWidgetOpen)}>
        <Pencil color="white" size={16} />
      </S.DropdownButton>
      <S.DropdownMenuWrapper>
        {isWidgetOpen && (
          <S.DropdownMenu ref={menuRef} {...attributes.popper}>
            <S.DropdownMenuItem onClick={onChangeSize}>Change Size</S.DropdownMenuItem>
            <S.DropdownMenuItem onClick={onMove}>Move</S.DropdownMenuItem>
            {onEdit ? <S.DropdownMenuItem onClick={handleEdit}>Edit</S.DropdownMenuItem> : null}
            <S.DropdownMenuItemRemove onClick={onRemove}>Remove</S.DropdownMenuItemRemove>
          </S.DropdownMenu>
        )}
      </S.DropdownMenuWrapper>
    </S.DropdownIconWrapper>
  );
};
