import styled from 'styled-components';

export const ImagesList = styled.div<{ $activeIndex: number }>`
  display: flex;
  height: 100%;
  width: 100%;
  transform: ${({ $activeIndex }) => `translateX(-${$activeIndex * 100}%)`};
  transition: transform 0.5s ease-in-out;
`;

export const ImageWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;
