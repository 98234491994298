import { useEffect, useMemo, useRef, useState } from 'react';
import { useSchemaStore } from 'stores/schema-store';
import { Announcement as AnnouncementType, AnnouncementsWidgetData, WidgetSizeEnum } from 'types/schema';
import { getBadgeLabelByWidgetSize } from 'utils/get-image-size-badge';

import { WidgetAnnouncementsDataModal } from 'components/modals/widget-announcements-data-modal';

import * as S from './widget-announcements.styled';
import { WidgetDropdown } from '../widget-dropdown';
import { Announcement } from './announcement';
import { ANNOUNCEMENTS_CAROUSEL_SLIDE_DURATION, VALIDATE_ANNOUNCEMENT_INTERVAL } from './constants';
import { filterAnnouncementsByDateAndTime, getSlidesCountBasedOnWidgetSize } from './utils';

interface WidgetAnnouncementsProps {
  widgetId: string;
  widgetSize: WidgetSizeEnum;
  data: AnnouncementsWidgetData | null;
}

export const WidgetAnnouncements = ({ widgetId, widgetSize, data }: WidgetAnnouncementsProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const currentSlideRef = useRef(-1);
  const schema = useSchemaStore((state) => state.schema);
  const setSchema = useSchemaStore((state) => state.setSchema);
  const [availableAnnouncements, setAvailableAnnouncements] = useState<AnnouncementType[]>([]);
  const [announcementsToRender, setAnnouncementsToRender] = useState<AnnouncementType[]>([]);

  const widget = useMemo(() => schema.find((item) => item.widgetId === widgetId), [schema, widgetId]);

  const displayedAnnouncementsCount = useMemo(() => getSlidesCountBasedOnWidgetSize(widgetSize), [widgetSize]);

  const filterAnnouncements = () => {
    if (!data || !data.announcements) return [];

    setAvailableAnnouncements(filterAnnouncementsByDateAndTime(data.announcements, new Date()));
  };

  useEffect(() => {
    filterAnnouncements();
    const interval = setInterval(() => filterAnnouncements(), VALIDATE_ANNOUNCEMENT_INTERVAL);
    return () => clearInterval(interval);
  }, [data?.announcements]);

  const totalSlides = useMemo(() => {
    if (!availableAnnouncements) return 0;
    return Math.ceil(availableAnnouncements.length / displayedAnnouncementsCount);
  }, [availableAnnouncements, displayedAnnouncementsCount]);

  const updateAnnouncementsSlides = () => {
    if (!availableAnnouncements || availableAnnouncements.length === 0) {
      return setAnnouncementsToRender([]);
    }
    currentSlideRef.current = (currentSlideRef.current + 1) % totalSlides;
    const startIndex = currentSlideRef.current * displayedAnnouncementsCount;
    const newAnnouncements = availableAnnouncements.slice(startIndex, startIndex + displayedAnnouncementsCount);
    setAnnouncementsToRender(newAnnouncements);
  };

  useEffect(() => {
    updateAnnouncementsSlides();
    const interval = setInterval(updateAnnouncementsSlides, ANNOUNCEMENTS_CAROUSEL_SLIDE_DURATION);
    return () => clearInterval(interval);
  }, [availableAnnouncements, displayedAnnouncementsCount, totalSlides]);

  const renderAnnouncements = () => {
    if (!announcementsToRender || announcementsToRender.length === 0) {
      if (data?.backupImageUrl) {
        return (
          <S.BackupImageWrapper>
            <S.BackupImage src={data.backupImageUrl} />
          </S.BackupImageWrapper>
        );
      }

      return <S.NoAnnouncementsWrapper>There are no announcements added</S.NoAnnouncementsWrapper>;
    }

    return (
      <S.AnnouncementsWrapper $widgetSize={widgetSize}>
        {announcementsToRender.map((announcement) => (
          <Announcement data={announcement} key={announcement.header} />
        ))}
      </S.AnnouncementsWrapper>
    );
  };

  const renderSlideIndicators = () => {
    if (totalSlides <= 1) return null;

    return (
      <S.SlideIndicators>
        {Array.from({ length: totalSlides }).map((_, index) => (
          <S.SlideIndicator key={index} $active={index === currentSlideRef.current} />
        ))}
      </S.SlideIndicators>
    );
  };

  const onEdit = (data: AnnouncementsWidgetData) => {
    const newSchema = schema.map((item) => {
      if (item.widgetId === widgetId) {
        return {
          ...item,
          data,
        };
      }

      return item;
    });

    setSchema(newSchema);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <S.WidgetWrapper>
        {renderAnnouncements()}
        {renderSlideIndicators()}
      </S.WidgetWrapper>
      <WidgetDropdown widgetId={widgetId} onEdit={() => setIsEditModalOpen(true)} />
      {isEditModalOpen ? (
        <WidgetAnnouncementsDataModal
          data={data}
          badge={getBadgeLabelByWidgetSize(widget?.size)}
          isOpen
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onEdit}
          submitText="Save"
        />
      ) : null}
    </>
  );
};
