import styled from 'styled-components';

import { ModalButton } from 'components/base/button';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UploadedImageWrapper = styled.div`
  width: 100%;
  height: 100px;
`;

export const UplodadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left;
`;

export const SubmitButton = styled(ModalButton)`
  align-self: flex-end;
`;

export const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.error};
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
`;

export const Error = styled.div`
  color: ${(props) => props.theme.error};
  font-size: 14px;
`;
