import { Trash } from '@phosphor-icons/react';
import React from 'react';

import { useUploadPhotos } from 'hooks/use-upload-photos';

import * as S from './images-uploader.styled';

interface ImagesUploaderProps {
  maxImages: number;
  imageUrls: string[];
  onCallback: (imageUrls: string[]) => void;
  onRemovePhoto?: (index: number) => void;
  badge?: React.ReactNode;
  error?: string;
}

export const ImagesUploader = ({
  maxImages = 1,
  imageUrls,
  error,
  badge,
  onRemovePhoto,
  onCallback,
}: ImagesUploaderProps) => {
  const { getInputProps, getRootProps, isLoading, isPayloadTooBig } = useUploadPhotos(onCallback, maxImages);

  const handleRemovePhoto = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    e.stopPropagation();
    if (onRemovePhoto) {
      onRemovePhoto(index);
    }
  };

  const renderImages = () => {
    if (!imageUrls || imageUrls.length <= 0) {
      return 'Click to open the dialog window or drag files here';
    }

    return imageUrls.map((image, index) => (
      <S.ImagePreviewWrapper key={index}>
        {onRemovePhoto ? (
          <S.RemovePhotoButton
            type="button"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleRemovePhoto(e, index)}
          >
            <Trash size={20} />
          </S.RemovePhotoButton>
        ) : null}
        <S.ImagePreview src={image as string} />
      </S.ImagePreviewWrapper>
    ));
  };

  return (
    <S.ImagesUploaderWrapper>
      <S.ButtonsWrapper>
        <S.AddPhotoButton {...getRootProps()} type="button" disabled={isLoading}>
          Add photos
          <S.AddPhotoPlusIcon disabled={isLoading}>+</S.AddPhotoPlusIcon>
        </S.AddPhotoButton>
        {badge}
      </S.ButtonsWrapper>
      <input {...getInputProps()} />
      <S.ImagesUploaderContainer
        $error={!!error || isPayloadTooBig}
        {...getRootProps()}
        style={{
          cursor: 'initial',
        }}
      >
        {isLoading ? 'Loading...' : renderImages()}
      </S.ImagesUploaderContainer>
      {error && <S.Error>{error}</S.Error>}
      {isPayloadTooBig && <S.Error>Single file exceeded 10MB size or whole payload exceeded 50MB size</S.Error>}
    </S.ImagesUploaderWrapper>
  );
};
