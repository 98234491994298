import { WidgetSizeEnum } from 'types/schema';

export const getWidgetSizeLabel = (size: WidgetSizeEnum) => {
  switch (size) {
    case WidgetSizeEnum.FullColumn:
      return 'Full Column';
    case WidgetSizeEnum.HalfColumn:
      return 'Half Column';
    case WidgetSizeEnum.HorizontalColumn:
      return 'Horizontal Column';
    case WidgetSizeEnum.SquareColumn:
      return 'Square Column';
    default:
      return null;
  }
};
