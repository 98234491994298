import { useQuery } from 'react-query';
import { useBuildingIdAtom } from 'stores/building-id-atom';

import { axiosInstance } from '../axiosInstance';

export interface HourWeather {
  date: number;
  temperature: number;
  iconId: string;
  chanceOfRain: number;
}

export interface WeatherData {
  current: {
    date: number;
    temperature: number;
    pressure: number;
    humidity: number;
    windSpeed: number;
    iconId: string;
    chanceOfRain: number;
  };
  hourly: HourWeather[];
}

export const getWeatherQueryKey = (buildingId: number | null) => ['weather', buildingId];

const getWeather = async (buildingId: number | null) => {
  const { data } = await axiosInstance.get<WeatherData>(`/weather/${buildingId}`);
  return data;
};

export const useGetWeather = () => {
  const [buildingId] = useBuildingIdAtom();

  return useQuery({
    enabled: !!buildingId,
    queryKey: getWeatherQueryKey(buildingId),
    queryFn: () => getWeather(buildingId),
    refetchOnWindowFocus: false,
    // Note: refetch every 5 minutes
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
