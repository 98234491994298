import styled from 'styled-components';

import { Card } from 'components/base/card';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SizesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
`;

export const SizeCard = styled(Card)<{ disabled: boolean }>`
  cursor: pointer;
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.text.primary};
  cursor: pointer;
  display: flex;
  width: min-content;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  margin-top: 8px;
`;
