export const INTERVAL_OPTIONS = [
  {
    label: '2 seconds',
    value: '2',
  },
  {
    label: '5 seconds',
    value: '5',
  },
  {
    label: '30 seconds',
    value: '30',
  },
  {
    label: '1 minute',
    value: '60',
  },
  {
    label: '3 minutes',
    value: '180',
  },
  {
    label: '5 minutes',
    value: '300',
  },
  {
    label: '10 minutes',
    value: '600',
  },
  {
    label: '20 minutes',
    value: '1200',
  },
];
