import { useQuery } from 'react-query';
import { useBuildingIdAtom } from 'stores/building-id-atom';

import { axiosInstance } from '../axiosInstance';

export interface PerkData {
  id: number;
  name: string;
  tag: string;
  image: string;
  logo: string;
  description: string;
}

export const getPerksQueryKey = (buildingId: number | null) => ['perks', buildingId];

const getPerks = async (buildingId: number | null) => {
  const { data } = await axiosInstance.get<PerkData[]>(`/neighbourhood/building/${buildingId}`);
  return data;
};

export const useGetPerks = () => {
  const [buildingId] = useBuildingIdAtom();

  return useQuery({
    enabled: !!buildingId,
    queryKey: getPerksQueryKey(buildingId),
    queryFn: () => getPerks(buildingId),
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
