import { useEffect } from 'react';
import { HashLoader } from 'react-spinners';
import { useAddWidgetStore } from 'stores/add-widget-store';
import { useBuildingIdAtom } from 'stores/building-id-atom';
import { useIsEditMode } from 'stores/edit-mode-enum';
import { useSchemaStore } from 'stores/schema-store';
import { translateOldSchema } from 'utils/translate-old-schema';

import { useGetSchema } from 'api/get-schema';

import * as S from './home.styled';
import { useAddWidgetService } from '../../services/add-widget-service';
import { Footer } from '../footer';
import { Header } from '../header';
import { LayoutEditor } from '../layout-editor';

export const Home = () => {
  const { renderAddWidgetModal, onSelectPlacement } = useAddWidgetService();
  const isOpen = useAddWidgetStore((state) => state.isOpen);
  const size = useAddWidgetStore((state) => state.size);
  const step = useAddWidgetStore((state) => state.step);
  const schema = useSchemaStore((state) => state.schema);
  const setSchema = useSchemaStore((state) => state.setSchema);
  const { data, isLoading } = useGetSchema();
  const [, setBuildingId] = useBuildingIdAtom();
  const [isEditMode] = useIsEditMode();

  useEffect(() => {
    if (data) {
      setBuildingId(data.buildingId);

      if (!data.schema) {
        return;
      }

      // Note: If submitted schema is in old format -> translate it to new one
      if ('schema' in data.schema) {
        setSchema(translateOldSchema(JSON.stringify(data.schema)));
        return;
      }

      setSchema(data.schema);
    }
  }, [data]);

  if (isLoading) {
    return (
      <S.Loading>
        <HashLoader />
      </S.Loading>
    );
  }

  return (
    <S.Home>
      <Header />
      <LayoutEditor
        schema={schema}
        onSelectPlacement={onSelectPlacement}
        isPlacementMode={isOpen && step === 'placement'}
        editModeSize={size}
      />
      {isEditMode ? <Footer /> : null}
      {isEditMode ? renderAddWidgetModal() : null}
    </S.Home>
  );
};
