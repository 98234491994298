import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Announcement } from 'types/schema';
import { formatDateToYearMonthDayDate } from 'utils/format-date';

export const useWidgetAnnouncementCreateFormik = (onCallback: (data: Announcement) => void) =>
  useFormik<Announcement>({
    initialValues: {
      duration: '12',
      header: '',
      imageUrls: [],
      isManuallyRemoved: false,
      startDate: '',
      startTime: '',
      description: '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      onCallback({ ...values, startDate: formatDateToYearMonthDayDate(values.startDate) });
    },
    validationSchema: Yup.object().shape({
      duration: Yup.string().when('isManuallyRemoved', (isManuallyRemoved, schema) =>
        isManuallyRemoved
          ? schema
          : schema.min(1, 'Duration is required when not manually removed').required('Duration is required'),
      ),
      header: Yup.string().required('Header is required'),
      imageUrls: Yup.array().min(1, 'Image is required'),
      isManuallyRemoved: Yup.boolean(),
      startDate: Yup.string().required('Start date is required'),
      startTime: Yup.string().required('Start time is required'),
      description: Yup.string().required('Description is required'),
    }),
  });
