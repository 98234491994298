import React from 'react';

import * as S from './input.styled';
import { Label } from '../label';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

export const Input = ({ label, error, ...props }: InputProps) => (
  <S.InputWrapper>
    {label && <Label htmlFor={props.name}>{label}</Label>}
    <S.Input $error={!!error} {...props} />
    {error && <S.Error>{error}</S.Error>}
  </S.InputWrapper>
);
