import DatePickerPrimitive from 'react-datepicker';
import styled from 'styled-components';

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .react-datepicker__tab-loop {
    margin-top: -8px;
  }
`;

export const DatePicker = styled(DatePickerPrimitive)<{ $error: boolean }>`
  border-radius: 4px;
  border: 1px solid ${(props) => (props.$error ? props.theme.error : props.theme.border.form)};
  background-color: ${(props) => props.theme.background.form};
  color: ${(props) => props.theme.text.primary};
  height: 35px;
  width: 100%;
`;

export const Error = styled.span`
  color: ${(props) => props.theme.error};
  font-size: 12px;
`;
