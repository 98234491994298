import * as Yup from 'yup';
import { useFormik } from 'formik';
import { RotatingGalleryWidgetData } from 'types/schema';

export const useWidgetRotatingGalleryDataFormik = (
  data: RotatingGalleryWidgetData,
  onCallback: (data: RotatingGalleryWidgetData) => void,
) =>
  useFormik<RotatingGalleryWidgetData>({
    initialValues: {
      images: data.images,
      interval: data.interval,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      onCallback(values);
    },
    validationSchema: Yup.object().shape({
      images: Yup.array().min(1, 'At least one image is required'),
      interval: Yup.number().required('Interval is required'),
    }),
  });
