import styled from 'styled-components';

export const CardWrapper = styled.div<{ disabled?: boolean }>`
  color: ${(props) => props.theme.text.primary};
  background-color: ${(props) => props.theme.background.cardModal};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  width: 100%;
  border: 1px solid ${(props) => (props.theme.isDarkMode ? 'none' : props.theme.border.primary)};

  &:hover {
    background-color: ${(props) => props.theme.background.cardModalHover};
  }

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    color: ${props.theme.text.secondary};
    background-color: ${props.theme.background.cardModalDisabled};
  `}
`;
