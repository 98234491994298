import styled from 'styled-components';

export const LayoutEditorFullColumnPicker = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 2vh;
  width: 100%;
  height: 100%;
  z-index: 20;
`;
