import styled from 'styled-components';

export const NoLocationPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-align: center;
  padding: 0 20px;
`;
