import { useFormik } from 'formik';
import { AutomatedEventFlyerWidgetData } from 'types/schema';

export const useAutomatedEventFlyerDataFormik = (
  data: AutomatedEventFlyerWidgetData,
  onCallback: (data: AutomatedEventFlyerWidgetData) => void,
) =>
  useFormik<AutomatedEventFlyerWidgetData>({
    initialValues: {
      interval: data.interval,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      onCallback(values);
    },
  });
