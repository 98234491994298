import styled from 'styled-components';

import { Button } from '../base/button';

export const Footer = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
`;

export const TemplateSelectWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
`;

export const DiscardTemplateButton = styled(Button)`
  background-color: white;
  color: ${({ theme }) => theme.text.secondary};
  border: 1px solid ${({ theme }) => theme.border.primary};
  height: 35px;
  margin-left: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.background.secondary};
  }
`;

export const ApplyTemplateButton = styled(Button)`
  height: 35px;
`;

export const AddWidgetButton = styled(Button)`
  font-size: 16px;
  padding: 16px 28px;
  border-radius: 50px;
  justify-self: center;
  height: fit-content;
  align-self: center;
`;

export const SaveAndPublishButton = styled(Button)`
  justify-self: end;
  height: fit-content;
  align-self: center;
`;
