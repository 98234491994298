import styled from 'styled-components';

export const Home = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${(props) => props.theme.background.primary};
  padding: 2vh 2vw;
  gap: 2vh;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  text-align: center;
  padding: 0 20px;
`;
