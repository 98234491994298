import { WidgetTypeEnum } from 'types/schema';
import { getWidgetIcon } from 'utils/get-widget-icon';
import { getWidgetLabel } from 'utils/get-widget-label';

import { Modal } from 'components/base/modal';

import * as S from './add-widget-type-modal.styled';

interface AddWidgetTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectWidgetType: (type: WidgetTypeEnum) => void;
}

export const AddWidgetTypeModal = ({ isOpen, onClose, onSelectWidgetType }: AddWidgetTypeModalProps) => (
  <Modal hideScroll onClose={onClose} isOpen={isOpen} header="Select widget type" size="large">
    <S.WidgetsWrapper>
      {Object.values(WidgetTypeEnum).map((type) => (
        <S.WidgetType key={type} onClick={() => onSelectWidgetType(type)}>
          {getWidgetIcon(type)}
          {getWidgetLabel(type)}
        </S.WidgetType>
      ))}
    </S.WidgetsWrapper>
  </Modal>
);
