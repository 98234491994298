import React from 'react';

import { Select } from '../select';
import { TIME_OPTIONS } from './constants';

interface TimePickerProps {
  selected: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  error?: string;
}

export const TimePicker = ({ selected, onChange, error, label }: TimePickerProps) => (
  <Select error={error} label={label} value={selected} onChange={onChange} options={TIME_OPTIONS} />
);
