import styled from 'styled-components';
import { SlotIdEnum } from 'types/schema';

export const LayoutEditorHorizontalColumnPicker = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2vh;
  width: 100%;
  height: 100%;
  grid-template-areas: '${SlotIdEnum.Slot1} ${SlotIdEnum.Slot1} ${SlotIdEnum.Slot2}' '${SlotIdEnum.Slot3} ${SlotIdEnum.Slot3} ${SlotIdEnum.Slot4}';
  z-index: 20;
`;
