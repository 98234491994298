import { create } from 'zustand';

import { Settings } from 'api/get-settings';

interface SettingsStore {
  settings: Settings;
  setSettings: (settings: Settings) => void;
}

const initialSettings: Settings = {
  isLogoHidden: false,
  isDarkMode: false,
  customLogoUrl: '',
  customLogoDarkUrl: '',
};

export const useSettingsStore = create<SettingsStore>((set) => ({
  settings: initialSettings,
  setSettings: (settings) => set({ settings }),
}));
