import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { useUploadFiles } from 'api/upload-files';

export const useUploadPhotos = (onCallback: (photoUrls: string[]) => void, maxFiles = 1) => {
  const { mutateAsync: uploadFiles, isLoading } = useUploadFiles();

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    onDrop: async (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) {
        return;
      }

      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append('files', file);
      });
      const { fileUrls } = await uploadFiles(formData);
      onCallback(fileUrls);
      toast.success('Photos uploaded successfully');
    },
    maxFiles,
    multiple: maxFiles > 1,
    maxSize: 10 * 1024 * 1024, // 10MB
  });

  const areFilesTooBig = fileRejections.some((file) => file.errors.some((error) => error.code === 'file-too-large'));

  return { getRootProps, getInputProps, isLoading, isPayloadTooBig: areFilesTooBig };
};
