import { useEffect, useMemo, useRef, useState } from 'react';
import { HashLoader } from 'react-spinners';
import { WidgetSizeEnum } from 'types/schema';

import { PerkData, useGetPerks } from 'api/get-perks';

import * as S from './widget-neighbourhood-perks.styled';
import { WidgetDropdown } from '../widget-dropdown';
import { NEIGHBOURHOOD_PERKS_CAROUSEL_SLIDE_DURATION } from './constants';
import { NeighbourhoodPerk } from './neighbourhood-perk';
import { getSlidesCountBasedOnWidgetSize } from './utils';

interface WidgetNeighbourhoodPerksProps {
  widgetSize: WidgetSizeEnum;
  widgetId: string;
}

export const WidgetNeighbourhoodPerks = ({ widgetId, widgetSize }: WidgetNeighbourhoodPerksProps) => {
  const { data: perks, isLoading } = useGetPerks();
  const [perksToRender, setPerksToRender] = useState<PerkData[]>([]);
  const currentSlideRef = useRef(-1);

  const displayedPerksCount = useMemo(() => getSlidesCountBasedOnWidgetSize(widgetSize), [widgetSize]);

  const totalSlides = useMemo(() => {
    if (!perks) return 0;
    return Math.ceil(perks.length / displayedPerksCount);
  }, [perks, displayedPerksCount]);

  const updatePerks = () => {
    if (!perks || perks.length === 0) return;
    currentSlideRef.current = (currentSlideRef.current + 1) % totalSlides;
    const startIndex = currentSlideRef.current * displayedPerksCount;
    const newPerks = perks.slice(startIndex, startIndex + displayedPerksCount);
    setPerksToRender(newPerks);
  };

  useEffect(() => {
    if (perks) {
      updatePerks();
    }

    const interval = setInterval(updatePerks, NEIGHBOURHOOD_PERKS_CAROUSEL_SLIDE_DURATION);
    return () => clearInterval(interval);
  }, [perks, displayedPerksCount, totalSlides]);

  const renderPerks = () => {
    if (isLoading) {
      return (
        <S.Loading>
          <HashLoader />
        </S.Loading>
      );
    }

    if (!perks || perks.length === 0) {
      return <S.NoNeighbourhoodPerksWrapper>There are no perks added</S.NoNeighbourhoodPerksWrapper>;
    }

    return perksToRender.map((perk) => <NeighbourhoodPerk key={perk.id} data={perk} />);
  };

  const renderSlideIndicators = () => (
    <S.SlideIndicators>
      {Array.from({ length: totalSlides }).map((_, index) => (
        <S.SlideIndicator key={index} $active={index === currentSlideRef.current} />
      ))}
    </S.SlideIndicators>
  );

  return (
    <S.WidgetWrapper>
      <S.NeighbourhoodPerksWrapper $widgetSize={widgetSize}>{renderPerks()}</S.NeighbourhoodPerksWrapper>
      {renderSlideIndicators()}
      <WidgetDropdown widgetId={widgetId} />
    </S.WidgetWrapper>
  );
};
