import styled from 'styled-components';

export const BadgeWrapper = styled.div`
  background-color: #fdf7ee;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 8px 16px;
  gap: 4px;
  color: #000;
  font-weight: 500;
  width: fit-content;
  color: #ee8e00;
`;
