import { ReactComponent as PoweredByWhite } from 'assets/logo/powered-by-white.svg';
import { ReactComponent as PoweredBy } from 'assets/logo/powered-by.svg';
import { ReactComponent as HumidityIcon } from 'assets/weather/humidity.svg';
import { ReactComponent as RainIcon } from 'assets/weather/rain.svg';
import { ReactComponent as TempIcon } from 'assets/weather/temp.svg';
import { ReactComponent as WindIcon } from 'assets/weather/wind.svg';
import { ReactComponent as AnnouncementsIcon } from 'assets/widget-icons/announcements.svg';
import { ReactComponent as AutomatedEventFlyer } from 'assets/widget-icons/automated-event-flyer.svg';
import { ReactComponent as CalendarIcon } from 'assets/widget-icons/calendar.svg';
import { ReactComponent as NeighborhoodPerksIcon } from 'assets/widget-icons/neighberhood-perks.svg';
import { ReactComponent as RotationGalleryIcon } from 'assets/widget-icons/rotating-gallery.svg';
import { ReactComponent as WeatherIcon } from 'assets/widget-icons/weather.svg';
import { ReactComponent as FullColumnIcon } from 'assets/widget-sizes/full-column.svg';
import { ReactComponent as HalfColumnIcon } from 'assets/widget-sizes/half-column.svg';
import { ReactComponent as HorizontalColumnIcon } from 'assets/widget-sizes/horizontal-column.svg';
import { ReactComponent as SquareColumn } from 'assets/widget-sizes/square-column.svg';

export const widgetIcons = {
  Weather: WeatherIcon,
  RotationGallery: RotationGalleryIcon,
  Announcements: AnnouncementsIcon,
  NeighborhoodPerks: NeighborhoodPerksIcon,
  AutomatedEventFlyerRotation: AutomatedEventFlyer,
  Calendar: CalendarIcon,
};

export const logo = {
  Regular: PoweredBy,
  White: PoweredByWhite,
};

export const widgetSizeIcons = {
  FullColumn: FullColumnIcon,
  HalfColumn: HalfColumnIcon,
  HorizontalColumn: HorizontalColumnIcon,
  SquareColumn,
};

export const weatherIcons = {
  Humidity: HumidityIcon,
  Rain: RainIcon,
  Temp: TempIcon,
  Wind: WindIcon,
};
