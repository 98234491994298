import styled from 'styled-components';

import { Button } from 'components/base/button';

export const CreateAnnouncementForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${(props) => props.theme.text.primary};
  width: 100%;
`;

export const CreateAnnouncementDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CreateAnnouncementDetailsRow = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  padding-bottom: 24px;
  margin-top: 12px;
`;

export const CreateAnnouncementButton = styled(Button)`
  align-self: flex-end;
`;
