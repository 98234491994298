import React from 'react';

import * as S from './no-location-page.styled';

export const NoLocationPage = () => (
  <S.NoLocationPageWrapper>
    To use this app, you need to create a location in Elevated Living dashboard and click edit button to get the
    location ID
  </S.NoLocationPageWrapper>
);
