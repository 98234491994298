/* eslint-disable @typescript-eslint/no-explicit-any */
import { LayoutItem, SlotIdEnum, WidgetSizeEnum, WidgetTypeEnum } from 'types/schema';

import { generateWidgetId } from './generate-widget-id';
import { getPlaceholderPhoto } from './get-placeholder-photo';

const defaultSlot: LayoutItem = {
  widget: null,
  widgetId: null,
  slotId: SlotIdEnum.Slot1,
  isCovered: false,
  size: WidgetSizeEnum.HalfColumn,
  data: null,
};

const filterIncorrectImage = (imageUrl: string) => !imageUrl.includes('blob:');

const regenerateImages = (files: unknown[] | null | undefined) => {
  if (!files) return [getPlaceholderPhoto()];
  const regeneratedImages = files.map((file: any) => file.preview).filter(filterIncorrectImage);
  return regeneratedImages.length ? regeneratedImages : [getPlaceholderPhoto()];
};

const getNewWidgetSizeSize = (element: any) => {
  if (element.isFullColumn) return WidgetSizeEnum.FullColumn;
  if (element.isHorizontalColumn) return WidgetSizeEnum.HorizontalColumn;
  if (element.isSquareColumn) return WidgetSizeEnum.SquareColumn;
  return WidgetSizeEnum.HalfColumn;
};

const recalculateWidgetSize = (size: WidgetSizeEnum, index: number, schema: LayoutItem[]) => {
  const indexesToChange: number[] = [];

  if (size === WidgetSizeEnum.FullColumn) {
    // TOP ROW
    if (index === 0) indexesToChange.push(3);
    if (index === 1) indexesToChange.push(4);
    if (index === 2) indexesToChange.push(5);

    // BOTTOM ROW
    if (index === 3 && schema[0].isCovered === false) indexesToChange.push(0);
    if (index === 4 && schema[1].isCovered === false) indexesToChange.push(1);
    if (index === 5 && schema[2].isCovered === false) indexesToChange.push(2);
  }

  if (size === WidgetSizeEnum.HorizontalColumn) {
    if (index === 0) indexesToChange.push(1);
    if (index === 1) indexesToChange.push(2);
    if (index === 2 && schema[1].isCovered === false) indexesToChange.push(1);
    if (index === 3) indexesToChange.push(4);
    if (index === 4) indexesToChange.push(5);
    if (index === 5 && schema[4].isCovered === false) indexesToChange.push(4);
  }

  if (size === WidgetSizeEnum.SquareColumn) {
    if (index === 0) indexesToChange.push(1, 3, 4);
    if (index === 1) indexesToChange.push(2, 4, 5);
  }

  return indexesToChange;
};

const mapWidgetData = (element: any, type: WidgetTypeEnum) => {
  switch (type) {
    case WidgetTypeEnum.RotationGallery:
      return {
        images: regenerateImages(element.rotatingGallery?.files),
        interval: element.rotatingGallery?.defaultDurationValue
          ? (element.rotatingGallery.defaultDurationValue / 1000).toString()
          : '5',
      };
    case WidgetTypeEnum.Announcements:
      return {
        backupImageUrl: '',
        announcements: element.announcements.map((announcement: any) => ({
          header: announcement.title,
          description: announcement.description,
          imageUrls: regenerateImages(announcement.files),
          startDate: announcement.date,
          startTime: announcement.time,
          duration: announcement.visibilityTime,
          isManuallyRemoved: false,
        })),
      };
    case WidgetTypeEnum.AutomatedEventFlyerRotation:
      return {
        interval: element.automatedEventFlyerTime ? (element.automatedEventFlyerTime / 1000).toString() : '5',
      };
    default:
      return null;
  }
};

export const translateOldSchema = (oldSchema: string) => {
  const schema = JSON.parse(oldSchema);

  // Note: If schema is already in new format -> return it
  if (!Array.isArray(schema.schema[0] || !Array.isArray(schema.schema[1]))) {
    return schema.schema;
  }

  const elements = [...schema.schema[0], ...schema.schema[1]];

  const newSchema = Array.from({ length: 6 })
    .fill(null)
    .map((_, index) => ({
      ...defaultSlot,
      slotId: SlotIdEnum[`Slot${index + 1}` as keyof typeof SlotIdEnum],
    }));

  elements.forEach((element, index) => {
    const typeMap = {
      Weather: WidgetTypeEnum.Weather,
      'Image/PDF Rotating Gallery': WidgetTypeEnum.RotationGallery,
      'Neighborhood perks': WidgetTypeEnum.NeighborhoodPerks,
      Calendar: WidgetTypeEnum.Calendar,
      Announcements: WidgetTypeEnum.Announcements,
      'Automated Event Flyer Rotation': WidgetTypeEnum.AutomatedEventFlyerRotation,
    };

    const widgetType = typeMap[element.type as keyof typeof typeMap];
    if (!widgetType) return;

    const newSize = getNewWidgetSizeSize(element);
    const indexesToChange = [index, ...recalculateWidgetSize(newSize, index, newSchema)];

    const widgetData = mapWidgetData(element, widgetType);
    const newItem = {
      widget: widgetType,
      widgetId: generateWidgetId(widgetType),
      isCovered: true,
      // Note: Make sure that widget takes more than one grid, otherwise leave default size
      ...(indexesToChange.length > 1 ? { size: newSize } : {}),
      data: widgetData,
    };

    indexesToChange.forEach((i) => {
      newSchema[i] = { ...newSchema[i], ...newItem };
    });
  });

  return newSchema;
};
