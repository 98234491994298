import styled from 'styled-components';

export const TextareaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const Textarea = styled.textarea<{ $error: boolean }>`
  padding: 8px;
  border: 1px solid ${(props) => (props.$error ? props.theme.error : props.theme.border.form)};
  background-color: ${(props) => props.theme.background.form};
  color: ${(props) => props.theme.text.primary};
  border-radius: 4px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
`;

export const Error = styled.span`
  color: ${(props) => props.theme.error};
  font-size: 12px;
`;
