import { Announcement as AnnouncementType } from 'types/schema';

import * as S from './announcement.styled';

interface AnnouncementProps {
  data: AnnouncementType;
}

export const Announcement = ({ data }: AnnouncementProps) => (
  <S.Container>
    <S.ImageContainer>
      <S.Image src={data.imageUrls[0]} />
    </S.ImageContainer>
    <S.InfoContainer>
      <S.Title>{data.header}</S.Title>
      <S.Description>{data.description}</S.Description>
    </S.InfoContainer>
  </S.Container>
);
