import styled from 'styled-components';
import { WidgetSizeEnum } from 'types/schema';
import { formatViewport } from 'utils/get-pixels-to-viewport';

import { getGridTemplate } from './utils';

export const WidgetWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 3%;
  gap: 2%;
`;

export const AnnouncementsWrapper = styled.div<{ $widgetSize: WidgetSizeEnum }>`
  position: relative;
  width: 100%;
  height: 100%;
  gap: 2%;
  display: grid;
  ${(props) => getGridTemplate(props.$widgetSize)};
`;

export const NoAnnouncementsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: ${formatViewport(24)};
  color: ${({ theme }) => theme.text.primary};
`;

export const SlideIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${formatViewport(10)};
  gap: ${formatViewport(10)};
`;

export const SlideIndicator = styled.div<{ $active: boolean }>`
  width: ${formatViewport(12)};
  height: ${formatViewport(12)};
  margin: 0;
  border-radius: 50%;
  background-color: ${({ $active }) => ($active ? 'black' : 'lightgray')};
  transition: background-color 0.3s ease;
`;

export const BackupImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const BackupImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`;
