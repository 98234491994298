import { WidgetTypeEnum } from 'types/schema';
import { widgetIcons } from 'utils/assets';

export const getWidgetIcon = (widget: WidgetTypeEnum) => {
  const Icon = widgetIcons[widget];

  if (Icon) {
    return <Icon />;
  }

  return null;
};
