import { AddWidgetStep, useAddWidgetStore } from 'stores/add-widget-store';
import { useSchemaStore } from 'stores/schema-store';
import {
  AnnouncementsWidgetData,
  AutomatedEventFlyerWidgetData,
  RotatingGalleryWidgetData,
  SlotIdEnum,
  WidgetData,
  WidgetSizeEnum,
  WidgetTypeEnum,
} from 'types/schema';
import { generateWidgetId } from 'utils/generate-widget-id';
import { useShallow } from 'zustand/react/shallow';

import { WidgetAnnouncementsDataModal } from 'components/modals/widget-announcements-data-modal';
import { WidgetAutomatedEventFlyerDataModal } from 'components/modals/widget-automated-event-flyer-data-modal';
import { WidgetRotationGalleryDataModal } from 'components/modals/widget-rotation-gallery-data-modal';

import { AddWidgetSizeModal } from '../components/modals/add-widget-size-modal';
import { AddWidgetTypeModal } from '../components/modals/add-widget-type-modal';
import { WIDGET_FLOWS_BY_TYPE_MAP } from './constants';

export const useAddWidgetService = () => {
  const setSchema = useSchemaStore((state) => state.setSchema);
  const schema = useSchemaStore((state) => state.schema);

  const {
    isOpen,
    onReset,
    editedWidgetSlotIds,
    data,
    setData,
    setIsOpen,
    setSize,
    setStep,
    setType,
    size,
    step,
    type,
  } = useAddWidgetStore(
    useShallow((state) => ({
      ...state,
    })),
  );

  const moveToNextStep = (widgetFlow: AddWidgetStep[]) => {
    const currentStepIndex = widgetFlow.indexOf(step);
    const nextStep = widgetFlow[currentStepIndex + 1];
    setStep(nextStep);
  };

  const moveToPreviousStep = (widgetFlow: AddWidgetStep[]) => {
    const currentStepIndex = widgetFlow.indexOf(step);
    const previousStep = widgetFlow[currentStepIndex - 1];
    setStep(previousStep);
  };

  const onCreateWidget = (slotIds: SlotIdEnum[]) => {
    const newWidgetId = generateWidgetId(type);

    const newSchema = schema.map((slot) => {
      if (!slotIds.includes(slot.slotId)) {
        // Note: If slot is being moved, reset old slots
        if (editedWidgetSlotIds.includes(slot.slotId)) {
          return {
            widget: null,
            slotId: slot.slotId,
            isCovered: false,
            size: WidgetSizeEnum.HalfColumn,
            widgetId: null,
            data: null,
          };
        }

        return {
          ...slot,
        };
      }

      return {
        widget: type,
        widgetId: newWidgetId,
        isCovered: true,
        size,
        slotId: slot.slotId,
        data,
      };
    });

    setSchema(newSchema);
  };

  const onClose = () => {
    setIsOpen(false);
    onReset();
  };

  const onSelectWidgetType = (type: WidgetTypeEnum) => {
    setType(type);
    moveToNextStep(WIDGET_FLOWS_BY_TYPE_MAP[type]);
  };

  const onSelectWidgetSize = (size: WidgetSizeEnum) => {
    setSize(size);
    moveToNextStep(WIDGET_FLOWS_BY_TYPE_MAP[type]);
  };

  const onSelectPlacement = (slotIds: SlotIdEnum[]) => {
    onCreateWidget(slotIds);
    onClose();
  };

  const onSelectWidgetData = (data: WidgetData) => {
    setData(data);
    moveToNextStep(WIDGET_FLOWS_BY_TYPE_MAP[type]);
  };

  const renderDataModal = () => {
    if (!isOpen) {
      return null;
    }

    switch (type) {
      case WidgetTypeEnum.RotationGallery:
        return (
          <WidgetRotationGalleryDataModal
            data={data ? (data as RotatingGalleryWidgetData) : undefined}
            isOpen
            onClose={onClose}
            onSubmit={onSelectWidgetData}
          />
        );
      case WidgetTypeEnum.AutomatedEventFlyerRotation:
        return (
          <WidgetAutomatedEventFlyerDataModal
            data={data ? (data as AutomatedEventFlyerWidgetData) : undefined}
            isOpen
            onClose={onClose}
            onSubmit={onSelectWidgetData}
          />
        );
      case WidgetTypeEnum.Announcements:
        return (
          <WidgetAnnouncementsDataModal
            data={data ? (data as AnnouncementsWidgetData) : undefined}
            submitText="Place widget"
            isOpen
            onClose={onClose}
            onSubmit={onSelectWidgetData}
          />
        );
      default:
        return null;
    }
  };

  const renderAddWidgetModal = () => {
    if (!isOpen) {
      return null;
    }

    switch (step) {
      case 'type':
        return <AddWidgetTypeModal onSelectWidgetType={onSelectWidgetType} isOpen onClose={onClose} />;
      case 'data':
        return renderDataModal();
      case 'size':
        return <AddWidgetSizeModal type={type} onSelectWidgetSize={onSelectWidgetSize} isOpen onClose={onClose} />;
      case 'placement':
        // Note: This step will be rendered in the EditorLayout
        return null;
      default:
        return null;
    }
  };

  return {
    renderAddWidgetModal,
    onSelectPlacement,
    onClose,
    moveToPreviousStep,
  };
};
