import styled from 'styled-components';
import { formatViewport } from 'utils/get-pixels-to-viewport';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${(props) => props.theme.background.primary};
  border: 1px solid black;
  border-radius: ${formatViewport(8)};
`;

export const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`;

export const Splitter = styled.div`
  width: 100%;
  height: ${formatViewport(1, 'vh')};
  background-color: ${(props) => props.theme.text.primary};
  margin-top: ${formatViewport(24)};
  margin-bottom: ${formatViewport(24)};
`;

export const MainContainer = styled.div`
  display: flex;
  height: 100%;
  padding: ${formatViewport(32, 'vh')} ${formatViewport(32)} 0 ${formatViewport(32)};
`;

export const Temperature = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: ${(props) => props.theme.text.primary};
`;

export const Time = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Degree = styled.div`
  font-size: ${formatViewport(51)};
  font-weight: 500;
  margin-left: ${formatViewport(8)};
  display: flex;
  align-items: center;
`;

export const TemperatureIconWrapper = styled.div`
  display: flex;
  height: ${formatViewport(72)};
`;

export const TemperatureTime = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: ${formatViewport(16)};
`;

export const Label = styled.div`
  font-size: ${formatViewport(25.6)};
  color: ${(props) => props.theme.text.primary};
`;

export const MainIcon = styled.img`
  width: ${formatViewport(92)};
  object-fit: cover;
`;

export const ClockContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${formatViewport(72)};
  color: ${(props) => props.theme.text.primary};
`;

export const ClockSuffix = styled.div`
  font-size: 1vw;
  font-weight: 600;
  margin-left: ${formatViewport(8)};
  height: 100%;
`;

export const ClockText = styled.div`
  font-size: ${formatViewport(72)};
  font-weight: 700;
`;

export const CurrentDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: ${formatViewport(32)};
  color: ${(props) => props.theme.text.primary};
`;

export const Wrapper = styled.div``;

export const TopWrapper = styled.div`
  padding-bottom: ${formatViewport(43, 'vh')};
  padding-left: ${formatViewport(35)};
  padding-right: ${formatViewport(26)};
  padding-top: ${formatViewport(48, 'vh')};
`;

export const GeneralInformation = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TemperatureWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.error};
`;

export const TimeWrapper = styled.div``;

export const WeatherInformation = styled.div``;

export const BottomWrapper = styled.div``;
