import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AnnouncementsWidgetData } from 'types/schema';

export const useWidgetAnnouncementsDataFormik = (
  data: AnnouncementsWidgetData,
  onCallback: (data: AnnouncementsWidgetData) => void,
) =>
  useFormik<AnnouncementsWidgetData>({
    initialValues: {
      backupImageUrl: data.backupImageUrl ?? '',
      announcements: data.announcements ?? [],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      onCallback(values);
    },
    validationSchema: Yup.object().shape({
      announcements: Yup.array().min(1, 'At least one announcement is required'),
    }),
  });
