import React from 'react';
import { useAddWidgetStore } from 'stores/add-widget-store';
import { useSchemaStore } from 'stores/schema-store';
import { SlotIdEnum } from 'types/schema';

import * as S from './layout-editor-horizontal-column-picker.styled';
import { LayoutEditorSlotPicker } from '../layout-editor-slot-picker';
import { isSlotCovered } from '../utils';

interface LayoutEditorHorizontalColumnPickerProps {
  onSelectPlacement: (slotIds: SlotIdEnum[]) => void;
}

export const HORIZONTAL_COLUMN_PLACEMENT_OPTIONS = [
  [SlotIdEnum.Slot1, SlotIdEnum.Slot2],
  [SlotIdEnum.Slot2, SlotIdEnum.Slot3],
  [SlotIdEnum.Slot4, SlotIdEnum.Slot5],
  [SlotIdEnum.Slot5, SlotIdEnum.Slot6],
];

export const LayoutEditorHorizontalColumnPicker = ({ onSelectPlacement }: LayoutEditorHorizontalColumnPickerProps) => {
  const schema = useSchemaStore((state) => state.schema);
  const editedWidgetSlotIds = useAddWidgetStore((state) => state.editedWidgetSlotIds);

  return (
    <S.LayoutEditorHorizontalColumnPicker>
      <LayoutEditorSlotPicker
        disabled={isSlotCovered(schema, HORIZONTAL_COLUMN_PLACEMENT_OPTIONS[0], editedWidgetSlotIds)}
        onSelectPlacement={() => onSelectPlacement(HORIZONTAL_COLUMN_PLACEMENT_OPTIONS[0])}
        gridArea={SlotIdEnum.Slot1}
      />
      <LayoutEditorSlotPicker
        disabled={isSlotCovered(schema, HORIZONTAL_COLUMN_PLACEMENT_OPTIONS[1], editedWidgetSlotIds)}
        onSelectPlacement={() => onSelectPlacement(HORIZONTAL_COLUMN_PLACEMENT_OPTIONS[1])}
        gridArea={SlotIdEnum.Slot2}
      />
      <LayoutEditorSlotPicker
        disabled={isSlotCovered(schema, HORIZONTAL_COLUMN_PLACEMENT_OPTIONS[2], editedWidgetSlotIds)}
        onSelectPlacement={() => onSelectPlacement(HORIZONTAL_COLUMN_PLACEMENT_OPTIONS[2])}
        gridArea={SlotIdEnum.Slot3}
      />
      <LayoutEditorSlotPicker
        disabled={isSlotCovered(schema, HORIZONTAL_COLUMN_PLACEMENT_OPTIONS[3], editedWidgetSlotIds)}
        onSelectPlacement={() => onSelectPlacement(HORIZONTAL_COLUMN_PLACEMENT_OPTIONS[3])}
        gridArea={SlotIdEnum.Slot4}
      />
    </S.LayoutEditorHorizontalColumnPicker>
  );
};
