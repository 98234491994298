import { AutomatedEventFlyerWidgetData } from 'types/schema';

import { ModalButton } from 'components/base/button';
import { Modal } from 'components/base/modal';
import { Select } from 'components/base/select';

import * as S from './widget-automated-event-flyer-data-modal.styled';
import { INTERVAL_OPTIONS } from './constants';
import { useAutomatedEventFlyerDataFormik } from './hooks';

interface WidgetAutomatedEventFlyerDataModalProps {
  data?: AutomatedEventFlyerWidgetData;
  isOpen: boolean;
  submitText?: string;
  onClose: () => void;
  onSubmit: (data: AutomatedEventFlyerWidgetData) => void;
}

export const WidgetAutomatedEventFlyerDataModal = ({
  isOpen,
  onClose,
  onSubmit,
  submitText,
  data,
}: WidgetAutomatedEventFlyerDataModalProps) => {
  const { handleSubmit, values, handleChange } = useAutomatedEventFlyerDataFormik(data ?? { interval: '5' }, onSubmit);

  return (
    <Modal
      hideScroll
      onClose={onClose}
      isOpen={isOpen}
      header="Automated Event Flyer"
      size="small"
      footer={
        <ModalButton type="submit" onClick={() => handleSubmit()}>
          {submitText ?? 'Add'}
        </ModalButton>
      }
    >
      <S.WidgetAutomatedEventFlyerForm>
        <Select
          options={INTERVAL_OPTIONS}
          label="Change PDF every..."
          id="interval"
          name="interval"
          value={values.interval}
          onChange={handleChange}
        />
      </S.WidgetAutomatedEventFlyerForm>
    </Modal>
  );
};
