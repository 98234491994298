import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
`;

export const Input = styled.input<{ $error: boolean }>`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => (props.$error ? props.theme.error : props.theme.border.form)};
  background-color: ${(props) => props.theme.background.form};
  color: ${(props) => props.theme.text};
`;

export const Error = styled.span`
  color: ${(props) => props.theme.error};
  font-size: 12px;
`;
