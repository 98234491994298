import { WidgetSizeEnum } from 'types/schema';

export const getGridTemplate = (widgetSize: WidgetSizeEnum) => {
  switch (widgetSize) {
    case WidgetSizeEnum.FullColumn:
      return 'grid-template-rows: 1fr 1fr; grid-template-columns: 1fr;';
    case WidgetSizeEnum.HalfColumn:
      return 'grid-template-rows: 1fr; grid-template-columns: 1fr';
    case WidgetSizeEnum.HorizontalColumn:
      return 'grid-template-rows: 1fr; grid-template-columns: 1fr 1fr;';
    case WidgetSizeEnum.SquareColumn:
      return 'grid-template-rows: 1fr 1fr; grid-template-columns: 1fr 1fr;';
    default:
      return '';
  }
};

export const getSlidesCountBasedOnWidgetSize = (widgetSize: WidgetSizeEnum) => {
  switch (widgetSize) {
    case WidgetSizeEnum.FullColumn:
      return 2;
    case WidgetSizeEnum.HalfColumn:
      return 1;
    case WidgetSizeEnum.HorizontalColumn:
      return 2;
    case WidgetSizeEnum.SquareColumn:
      return 4;
    default:
      return 1;
  }
};
