import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';
import { colors } from 'utils/generate-theme';

import { ModalSize } from './modal';
import { getModalSizeProperties } from './utils';

export const ModalOverlay = styled.div`
  background-color: ${hexToRgba(colors.black, 0.9)};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
`;

export const Modal = styled.div<{ size: ModalSize }>`
  max-width: 90vw;
  max-height: 90vh;
  background-color: ${(props) => props.theme.background.modal};
  z-index: 50;
  border-radius: 8px;
  ${(props) => getModalSizeProperties(props.size)};
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-size: 20px;
  color: ${(props) => props.theme.text.primary};
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.text.primary};
`;

export const ModalBody = styled.div<{ $hideScroll: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px 16px 16px 16px;
  overflow-y: scroll;

  ${(props) =>
    props.$hideScroll &&
    `
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  `}
`;

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`;
