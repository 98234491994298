import { X } from '@phosphor-icons/react';
import React from 'react';
import { createPortal } from 'react-dom';

import * as S from './modal.styled';

interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  header: React.ReactNode;
  footer?: React.ReactNode;
  onClose: () => void;
  size?: ModalSize;
  hideScroll?: boolean;
}

export type ModalSize = 'small' | 'medium' | 'large';

export const Modal = ({
  hideScroll = false,
  children,
  isOpen,
  header,
  footer,
  onClose,
  size = 'medium',
}: ModalProps) => {
  if (!isOpen) {
    return null;
  }

  return createPortal(
    <S.ModalOverlay>
      <S.Modal size={size}>
        <S.ModalHeader>
          <span>{header}</span>
          <S.ModalCloseButton onClick={onClose}>
            <X />
          </S.ModalCloseButton>
        </S.ModalHeader>
        <S.ModalBody $hideScroll={hideScroll}>{children}</S.ModalBody>
        {footer ? <S.ModalFooter>{footer}</S.ModalFooter> : null}
      </S.Modal>
    </S.ModalOverlay>,
    document.body,
  );
};
