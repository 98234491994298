import { Plus } from '@phosphor-icons/react';
import React from 'react';
import { SlotIdEnum } from 'types/schema';
import { colors } from 'utils/generate-theme';

import * as SS from '../layout-editor.styled';
import * as S from './layout-editor-slot-picker.styled';

interface LayoutEditorSlotPickerProps {
  onSelectPlacement: () => void;
  disabled: boolean;
  gridArea?: SlotIdEnum;
}

export const LayoutEditorSlotPicker = ({ disabled, onSelectPlacement, gridArea }: LayoutEditorSlotPickerProps) => (
  <S.LayoutEditorSlotPicker $disabled={disabled} $gridArea={gridArea}>
    {disabled ? (
      'The cell is taken by another widget'
    ) : (
      <SS.PlusButton onClick={disabled ? undefined : onSelectPlacement}>
        <Plus color={colors.plusIcon} width="50%" height="50%" />
      </SS.PlusButton>
    )}
  </S.LayoutEditorSlotPicker>
);
