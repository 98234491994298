import * as S from './widget-weather-hourly-details.styled';

interface WidgetWeatherHourlyDetailsProps {
  label: string;
  icon: string;
  temp: number;
  chanceOfRain: number;
}

export const WidgetWeatherHourlyDetails = ({ label, icon, temp, chanceOfRain }: WidgetWeatherHourlyDetailsProps) => (
  <S.Container>
    <S.Label>{label}</S.Label>
    <S.Image src={`http://openweathermap.org/img/wn/${icon}@4x.png`} />
    <S.SmallText>{Math.round(temp)}&deg;F</S.SmallText>
    <S.SmallText>{`${Math.round(chanceOfRain * 100)}% rain`}</S.SmallText>
  </S.Container>
);
