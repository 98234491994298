import React from 'react';

import * as S from './textarea.styled';
import { Label } from '../label';

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
}

export const Textarea = ({ label, error, ...props }: TextareaProps) => (
  <S.TextareaWrapper>
    {label && <Label htmlFor={props.name}>{label}</Label>}
    <S.Textarea $error={!!error} {...props} />
    {error && <S.Error>{error}</S.Error>}
  </S.TextareaWrapper>
);
