import { LayoutItem, Schema, SlotIdEnum, WidgetTypeEnum } from 'types/schema';

export const generateGridTemplateArea = (schema: Schema) => {
  const topRow = schema
    .slice(0, 3)
    .map((item) => item.widgetId ?? item.slotId)
    .join(' ');
  const bottomRow = schema
    .slice(3, 6)
    .map((item) => item.widgetId ?? item.slotId)
    .join(' ');

  return `"${topRow}" "${bottomRow}"`;
};

export const isSlotCovered = (schema: Schema, slotIds: SlotIdEnum[], omittedSlotIds: SlotIdEnum[]) => {
  const slotIdsToCheck = new Set(slotIds.filter((slotId) => !omittedSlotIds.includes(slotId)));

  return schema.flat().some((slot) => slotIdsToCheck.has(slot.slotId) && slot.isCovered);
};

export const generateGridArea = (slot: LayoutItem) => {
  const gridArea = slot.widgetId ?? slot.slotId;
  return gridArea;
};

export const getLayoutItemBorder = (widget: WidgetTypeEnum | null, color: string) => {
  if (widget) {
    if (widget === WidgetTypeEnum.RotationGallery) {
      return `none`;
    }

    return `1px solid ${color}`;
  }

  return `1px dashed ${color}`;
};
