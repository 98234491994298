import { useMutation, useQueryClient } from 'react-query';
import { getLocationIdFromUrl } from 'utils/get-location-id-from-url';

import { axiosInstance } from '../axiosInstance';
import { Settings, getSettingsQueryKey } from './get-settings';

interface SaveSettingsBody {
  locationId: string;
  settings: Settings;
}

export const saveSettings = async ({ locationId, settings }: SaveSettingsBody) => {
  const response = await axiosInstance.put<Settings>(`/tv-app/locationId/${locationId}/settings`, settings);
  return response.data;
};

export const useSaveSettings = () => {
  const queryClient = useQueryClient();
  const locationId = getLocationIdFromUrl(window.location.href);

  return useMutation({
    mutationFn: saveSettings,
    onSuccess: () => {
      queryClient.invalidateQueries(getSettingsQueryKey(locationId));
    },
  });
};
