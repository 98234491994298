import { Schema } from 'types/schema';

export const getSchemaWithoutWidget = (schema: Schema, widgetId: string) =>
  schema.map((item) => {
    if (item.widgetId === widgetId) {
      return {
        ...item,
        widget: null,
        widgetId: null,
        data: null,
        isCovered: false,
      };
    }

    return item;
  });
