import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';
import { colors } from 'utils/generate-theme';

export const DropdownIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const DropdownMenuWrapper = styled.div`
  position: relative;
`;

export const DropdownButton = styled.button`
  background-color: black;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${(props) => props.theme.background.dropdown};
  border-radius: 8px;
  box-shadow: 0px 4px 10px ${hexToRgba(colors.black, 0.1)};
  min-width: 200px;
  z-index: 10;
  overflow: hidden;
  color: ${(props) => props.theme.text.primary};
`;

export const DropdownMenuItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.background.secondary};
  }
`;

export const DropdownMenuItemRemove = styled(DropdownMenuItem)`
  background-color: ${(props) => props.theme.background.error};
  color: ${(props) => props.theme.error};
`;
