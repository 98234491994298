import { Schema, SlotIdEnum, WidgetSizeEnum, WidgetTypeEnum } from 'types/schema';
import { getPlaceholderPhoto } from 'utils/get-placeholder-photo';

export const CalendarWeatherGalleryAnnouncementsSchema: Schema = [
  {
    widget: WidgetTypeEnum.Calendar,
    widgetId: 'calendar-1',
    slotId: SlotIdEnum.Slot1,
    isCovered: true,
    size: WidgetSizeEnum.FullColumn,
    data: null,
  },
  {
    widget: WidgetTypeEnum.Weather,
    widgetId: 'weather-1',
    slotId: SlotIdEnum.Slot2,
    isCovered: true,
    size: WidgetSizeEnum.HalfColumn,
    data: null,
  },
  {
    widget: WidgetTypeEnum.Announcements,
    slotId: SlotIdEnum.Slot3,
    isCovered: true,
    size: WidgetSizeEnum.FullColumn,
    widgetId: 'announcements-1',
    data: {
      announcements: [],
      backupImageUrl: undefined,
    },
  },
  {
    widget: WidgetTypeEnum.Calendar,
    widgetId: 'calendar-1',
    slotId: SlotIdEnum.Slot4,
    isCovered: true,
    size: WidgetSizeEnum.FullColumn,
    data: null,
  },
  {
    widget: WidgetTypeEnum.RotationGallery,
    widgetId: 'gallery-1',
    slotId: SlotIdEnum.Slot5,
    isCovered: true,
    size: WidgetSizeEnum.HalfColumn,
    data: {
      images: [getPlaceholderPhoto()],
      interval: '5',
    },
  },
  {
    widget: WidgetTypeEnum.Announcements,
    slotId: SlotIdEnum.Slot6,
    isCovered: true,
    size: WidgetSizeEnum.FullColumn,
    widgetId: 'announcements-1',
    data: {
      announcements: [],
      backupImageUrl: undefined,
    },
  },
];

const AnnouncementsGalleryCalendarPerksSchema: Schema = [
  {
    widget: WidgetTypeEnum.Announcements,
    widgetId: 'annoucements-1',
    slotId: SlotIdEnum.Slot1,
    isCovered: true,
    size: WidgetSizeEnum.HorizontalColumn,
    data: {
      announcements: [],
      backupImageUrl: undefined,
    },
  },
  {
    widget: WidgetTypeEnum.Announcements,
    widgetId: 'annoucements-1',
    slotId: SlotIdEnum.Slot2,
    isCovered: true,
    size: WidgetSizeEnum.HorizontalColumn,
    data: {
      announcements: [],
      backupImageUrl: undefined,
    },
  },
  {
    widget: WidgetTypeEnum.Calendar,
    widgetId: 'calendar-1',
    slotId: SlotIdEnum.Slot3,
    isCovered: true,
    size: WidgetSizeEnum.HalfColumn,
    data: null,
  },
  {
    widget: WidgetTypeEnum.RotationGallery,
    widgetId: 'gallery-1',
    slotId: SlotIdEnum.Slot4,
    isCovered: true,
    size: WidgetSizeEnum.HorizontalColumn,
    data: {
      images: [getPlaceholderPhoto()],
      interval: '5',
    },
  },
  {
    widget: WidgetTypeEnum.RotationGallery,
    widgetId: 'gallery-1',
    slotId: SlotIdEnum.Slot5,
    isCovered: true,
    size: WidgetSizeEnum.HorizontalColumn,
    data: {
      images: [getPlaceholderPhoto()],
      interval: '5',
    },
  },
  {
    widget: WidgetTypeEnum.NeighborhoodPerks,
    widgetId: 'perks-1',
    slotId: SlotIdEnum.Slot6,
    isCovered: true,
    size: WidgetSizeEnum.HalfColumn,
    data: null,
  },
];

const AnnouncementsWeatherGallerySchema = [
  {
    widget: WidgetTypeEnum.Announcements,
    widgetId: 'annoucements-1',
    slotId: SlotIdEnum.Slot1,
    isCovered: true,
    size: WidgetSizeEnum.HalfColumn,
    data: {
      announcements: [],
      backupImageUrl: undefined,
    },
  },
  {
    widget: WidgetTypeEnum.RotationGallery,
    widgetId: 'gallery-1',
    slotId: SlotIdEnum.Slot2,
    isCovered: true,
    size: WidgetSizeEnum.SquareColumn,
    data: {
      images: [getPlaceholderPhoto()],
      interval: '5',
    },
  },
  {
    widget: WidgetTypeEnum.RotationGallery,
    widgetId: 'gallery-1',
    slotId: SlotIdEnum.Slot3,
    isCovered: true,
    size: WidgetSizeEnum.SquareColumn,
    data: {
      images: [getPlaceholderPhoto()],
      interval: '5',
    },
  },
  {
    widget: WidgetTypeEnum.Weather,
    widgetId: 'weather-1',
    slotId: SlotIdEnum.Slot4,
    isCovered: true,
    size: WidgetSizeEnum.HalfColumn,
    data: null,
  },
  {
    widget: WidgetTypeEnum.RotationGallery,
    widgetId: 'gallery-1',
    slotId: SlotIdEnum.Slot5,
    isCovered: true,
    size: WidgetSizeEnum.SquareColumn,
    data: {
      images: [getPlaceholderPhoto()],
      interval: '5',
    },
  },
  {
    widget: WidgetTypeEnum.RotationGallery,
    widgetId: 'gallery-1',
    slotId: SlotIdEnum.Slot6,
    isCovered: true,
    size: WidgetSizeEnum.SquareColumn,
    data: {
      images: [getPlaceholderPhoto()],
      interval: '5',
    },
  },
];

const GalleryCalendarPerksWeatherSchema = [
  {
    widget: WidgetTypeEnum.RotationGallery,
    widgetId: 'gallery-1',
    slotId: SlotIdEnum.Slot1,
    isCovered: true,
    size: WidgetSizeEnum.FullColumn,
    data: {
      images: [getPlaceholderPhoto()],
      interval: '5',
    },
  },
  {
    widget: WidgetTypeEnum.Calendar,
    widgetId: 'calendar-1',
    slotId: SlotIdEnum.Slot2,
    isCovered: true,
    size: WidgetSizeEnum.FullColumn,
    data: null,
  },
  {
    widget: WidgetTypeEnum.NeighborhoodPerks,
    widgetId: 'perks-1',
    slotId: SlotIdEnum.Slot3,
    isCovered: true,
    size: WidgetSizeEnum.HalfColumn,
    data: null,
  },
  {
    widget: WidgetTypeEnum.RotationGallery,
    widgetId: 'gallery-1',
    slotId: SlotIdEnum.Slot4,
    isCovered: true,
    size: WidgetSizeEnum.FullColumn,
    data: {
      images: [getPlaceholderPhoto()],
      interval: '5',
    },
  },
  {
    widget: WidgetTypeEnum.Calendar,
    widgetId: 'calendar-1',
    slotId: SlotIdEnum.Slot5,
    isCovered: true,
    size: WidgetSizeEnum.FullColumn,
    data: null,
  },
  {
    widget: WidgetTypeEnum.Weather,
    widgetId: 'weather-1',
    slotId: SlotIdEnum.Slot6,
    isCovered: true,
    size: WidgetSizeEnum.HalfColumn,
    data: null,
  },
];

export const templates = {
  CalendarWeatherGalleryAnnouncements: CalendarWeatherGalleryAnnouncementsSchema,
  AnnouncementsGalleryCalendarPerks: AnnouncementsGalleryCalendarPerksSchema,
  AnnouncementsWeatherGallery: AnnouncementsWeatherGallerySchema,
  GalleryCalendarPerksWeather: GalleryCalendarPerksWeatherSchema,
};

export type Template = keyof typeof templates;

export const templateOptions = [
  {
    label: 'Calendar, Weather, Gallery, Announcements',
    value: 'CalendarWeatherGalleryAnnouncements',
  },
  {
    label: 'Announcements, Gallery, Calendar, Perks',
    value: 'AnnouncementsGalleryCalendarPerks',
  },
  {
    label: 'Announcements, Weather, Gallery',
    value: 'AnnouncementsWeatherGallery',
  },
  {
    label: 'Gallery, Calendar, Perks, Weather',
    value: 'GalleryCalendarPerksWeather',
  },
];
