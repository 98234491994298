import { SlotIdEnum, WidgetData, WidgetSizeEnum, WidgetTypeEnum } from 'types/schema';
import { create } from 'zustand';

export type AddWidgetStep = 'type' | 'size' | 'data' | 'placement';

interface AddWidgetStore {
  isOpen: boolean;
  step: AddWidgetStep;
  size: WidgetSizeEnum;
  type: WidgetTypeEnum;
  data: WidgetData;
  editedWidgetSlotIds: SlotIdEnum[];
  setIsOpen: (isOpen: boolean) => void;
  setEditedWidgetSlotIds: (slotIds: SlotIdEnum[]) => void;
  setStep: (step: AddWidgetStep) => void;
  setType: (type: WidgetTypeEnum) => void;
  setSize: (size: WidgetSizeEnum) => void;
  setData: (data: WidgetData) => void;
  onReset: () => void;
}

const initialState = {
  isOpen: false,
  step: 'type' as AddWidgetStep,
  editedWidgetSlotIds: [],
  type: WidgetTypeEnum.Announcements,
  size: WidgetSizeEnum.HalfColumn,
  data: null,
};

export const useAddWidgetStore = create<AddWidgetStore>((set) => ({
  ...initialState,
  setIsOpen: (isOpen) => set({ isOpen }),
  setStep: (step) => set({ step }),
  setType: (type) => set({ type }),
  setSize: (size) => set({ size }),
  setData: (data) => set({ data }),
  setEditedWidgetSlotIds: (slotIds) => set({ editedWidgetSlotIds: slotIds }),
  onReset: () => set((state) => ({ ...state, ...initialState })),
}));
