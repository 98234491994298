import { useEffect } from 'react';
import { useSettingsStore } from 'stores/settings-store';
import { getLocationIdFromUrl } from 'utils/get-location-id-from-url';

import { useGetSettings } from 'api/get-settings';
import { useSaveSettings } from 'api/save-settings';

import { ModalButton } from 'components/base/button';
import { Checkbox } from 'components/base/checkbox';
import { Label } from 'components/base/label';
import { Modal } from 'components/base/modal';

import { useUploadPhoto } from 'hooks/use-upload-photo';

import * as S from './settings-modal.styled';

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SettingsModal = ({ isOpen, onClose }: SettingsModalProps) => {
  const { data: backendSettings } = useGetSettings();
  const { mutateAsync: saveSettings, isLoading } = useSaveSettings();
  const settings = useSettingsStore((state) => state.settings);
  const setSettings = useSettingsStore((state) => state.setSettings);
  const locationId = getLocationIdFromUrl(window.location.href);

  useEffect(() => {
    if (backendSettings) {
      setSettings(backendSettings);
    }
  }, [backendSettings]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await saveSettings({ locationId, settings });
    onClose();
  };

  const onChangeCustomLogoUrl = (photoUrl: string) => {
    setSettings({ ...settings, customLogoUrl: photoUrl });
  };

  const onChangeCustomLogoDarkUrl = (photoUrl: string) => {
    setSettings({ ...settings, customLogoDarkUrl: photoUrl });
  };

  const onChangeIsLogoHidden = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, isLogoHidden: event.target.checked });
  };

  const onChangeIsDarkMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({ ...settings, isDarkMode: event.target.checked });
  };

  const {
    getRootProps: getRootPropsLogoUpload,
    getInputProps: getInputPropsLogoUpload,
    isFileTooBig: isLogoFileTooBig,
  } = useUploadPhoto(onChangeCustomLogoUrl);

  const {
    getRootProps: getRootPropsLogoDarkUpload,
    getInputProps: getInputPropsLogoDarkUpload,
    isFileTooBig: isDarkLogoFileTooBig,
  } = useUploadPhoto(onChangeCustomLogoDarkUrl);

  return (
    <Modal hideScroll onClose={onClose} isOpen={isOpen} header="Settings" size="small">
      <S.Form onSubmit={onSubmit}>
        <Checkbox
          label="Hide Logo"
          id="isLogoHidden"
          name="isLogoHidden"
          type="checkbox"
          checked={settings.isLogoHidden}
          onChange={onChangeIsLogoHidden}
        />
        <Checkbox
          label="Dark Mode"
          id="isDarkMode"
          name="isDarkMode"
          type="checkbox"
          checked={settings.isDarkMode}
          onChange={onChangeIsDarkMode}
        />
        <S.UploadWrapper>
          <Label htmlFor="customLogoUrl">Upload a custom Logo</Label>
          {settings.customLogoUrl ? (
            <>
              <S.UploadedImageWrapper>
                <S.UplodadedImage src={settings.customLogoUrl} alt="custom logo" />
              </S.UploadedImageWrapper>
              <S.RemoveButton onClick={() => onChangeCustomLogoUrl('')}>Remove logo</S.RemoveButton>
            </>
          ) : (
            <>
              <ModalButton {...getRootPropsLogoUpload()} type="button">
                Upload custom logo
              </ModalButton>
              {isLogoFileTooBig && <S.Error>File is too big</S.Error>}
            </>
          )}
        </S.UploadWrapper>
        <S.UploadWrapper>
          <Label htmlFor="customLogoDarkUrl">Upload a custom Logo (Dark Mode)</Label>
          {settings.customLogoDarkUrl ? (
            <>
              <S.UploadedImageWrapper>
                <S.UplodadedImage src={settings.customLogoDarkUrl} alt="custom logo dark" />
              </S.UploadedImageWrapper>
              <S.RemoveButton onClick={() => onChangeCustomLogoDarkUrl('')}>Remove logo</S.RemoveButton>
            </>
          ) : (
            <>
              <ModalButton {...getRootPropsLogoDarkUpload()} type="button">
                Upload custom logo dark
              </ModalButton>
              {isDarkLogoFileTooBig && <S.Error>File is too big</S.Error>}
            </>
          )}
        </S.UploadWrapper>
        <input {...getInputPropsLogoUpload()} />
        <input {...getInputPropsLogoDarkUpload()} />
        <S.SubmitButton type="submit" isLoading={isLoading}>
          Apply changes
        </S.SubmitButton>
      </S.Form>
    </Modal>
  );
};
