import { ModalSize } from './modal';

export const getModalSizeProperties = (size: ModalSize) => {
  if (size === 'small') {
    return {
      'max-width': '30%',
      'max-height': '90%',
      width: '500px',
    };
  }

  if (size === 'medium') {
    return {
      'max-width': '50%',
      'max-height': '90%',
      width: '700px',
    };
  }

  return {
    'max-width': '70%',
    'max-height': '90%',
    width: '900px',
  };
};
