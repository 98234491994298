import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Checkbox = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
