import styled from 'styled-components';
import { formatViewport } from 'utils/get-pixels-to-viewport';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.text.primary};
`;

export const Label = styled.div`
  font-size: ${formatViewport(16)};
`;

export const Image = styled.img`
  width: ${formatViewport(36)};
`;

export const SmallText = styled.div`
  font-size: ${formatViewport(12.8)};
`;
