import styled from 'styled-components';
import { formatViewport } from 'utils/get-pixels-to-viewport';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: ${formatViewport(1)} solid ${({ theme }) => theme.border.primary};
  border-radius: 2%;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3%;
  color: ${({ theme }) => theme.text.primary};
`;

export const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${formatViewport(23)};
`;

export const Title = styled.div`
  font-weight: 500;
  white-space: nowrap;
`;

export const Date = styled.div`
  display: flex;
  white-space: nowrap;
  justify-content: flex-end;
  width: 100%;
  gap: 3%;
`;

export const DateDivider = styled.div`
  width: ${formatViewport(1)};
  height: 100%;
  background-color: ${({ theme }) => theme.border.primary};
`;

export const BottomRow = styled.div`
  margin-top: ${formatViewport(15)};
  display: grid;
  gap: ${formatViewport(10)};
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'location capacity' 'description description';
  font-size: ${formatViewport(16)};
`;

export const IconValueContainer = styled.div<{ $gridArea: string }>`
  display: flex;
  gap: ${formatViewport(10)};
  align-items: center;
  grid-area: ${({ $gridArea }) => $gridArea};

  svg {
    display: flex;
    flex-shrink: 0;
    width: ${formatViewport(16)};
    aspect-ratio: 1;
    height: ${formatViewport(16)};
  }
`;
