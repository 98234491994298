import { useMutation, useQueryClient } from 'react-query';
import { Schema } from 'types/schema';

import { axiosInstance } from '../axiosInstance';
import { GetSchema } from './get-schema';

export const saveSchema = async ({ locationId, schema }: { locationId: string; schema: Schema }) => {
  const response = await axiosInstance.put(`/tv-app/locationId/${locationId}/schema`, {
    tvAppSchema: JSON.stringify(schema),
  });
  return response.data as GetSchema;
};

export const useSaveSchema = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: saveSchema,
    onSuccess: () => {
      queryClient.invalidateQueries('schema');
    },
  });
};
