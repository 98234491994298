import styled from 'styled-components';
import { formatViewport } from 'utils/get-pixels-to-viewport';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: ${formatViewport(1)} solid ${({ theme }) => theme.border.primary};
  border-radius: 2%;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: ${formatViewport(10)};
  left: ${formatViewport(10)};
  width: 20%;
  aspect-ratio: 16/9;

  max-height: 70%;
`;

export const Logo = styled.img`
  object-fit: contain;
  object-position: bottom left;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3%;
  color: ${({ theme }) => theme.text.primary};
`;

export const Title = styled.div`
  font-weight: 500;
  white-space: nowrap;
  font-size: ${formatViewport(23)};
`;

export const Description = styled.p`
  margin-top: ${formatViewport(15)};
  font-size: ${formatViewport(16)};
`;
