import { RotatingGalleryWidgetData } from 'types/schema';

import { Badge } from 'components/base/badge';
import { ModalButton } from 'components/base/button';
import { ImagesUploader } from 'components/base/images-uploader/images-uploader';
import { Modal } from 'components/base/modal';
import { Select } from 'components/base/select';

import * as S from './widget-rotation-gallery-data-modal.styled';
import { INTERVAL_OPTIONS } from './constants';
import { useWidgetRotatingGalleryDataFormik } from './hooks';

interface WidgetRotationGalleryDataModalProps {
  data?: RotatingGalleryWidgetData;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: RotatingGalleryWidgetData) => void;
  submitText?: string;
  badge?: string;
}

export const WidgetRotationGalleryDataModal = ({
  isOpen,
  data,
  onClose,
  onSubmit,
  submitText,
  badge,
}: WidgetRotationGalleryDataModalProps) => {
  const { handleSubmit, values, handleChange, errors, setFieldValue } = useWidgetRotatingGalleryDataFormik(
    data ?? { images: [], interval: '5' },
    onSubmit,
  );

  const onUploadPhotos = (images: string[]) => {
    setFieldValue('images', [...values.images, ...images]);
  };

  const onRemovePhoto = (index: number) => {
    const newImages = values.images.filter((_, i) => i !== index);
    setFieldValue('images', newImages);
  };

  return (
    <Modal
      hideScroll
      onClose={onClose}
      isOpen={isOpen}
      header="Image Rotating Gallery"
      size="small"
      footer={
        <ModalButton type="submit" onClick={() => handleSubmit()}>
          {submitText ?? 'Add'}
        </ModalButton>
      }
    >
      <S.WidgetRotationGalleryForm>
        <ImagesUploader
          badge={badge ? <Badge>{badge}</Badge> : null}
          imageUrls={values.images}
          onCallback={onUploadPhotos}
          onRemovePhoto={onRemovePhoto}
          maxImages={5}
          error={Array.isArray(errors.images) ? errors.images[0] : errors.images}
        />
        <Select
          options={INTERVAL_OPTIONS}
          label="Change image every..."
          id="interval"
          name="interval"
          value={values.interval}
          onChange={handleChange}
          error={errors.interval}
        />
      </S.WidgetRotationGalleryForm>
    </Modal>
  );
};
