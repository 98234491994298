import { ArrowLeft } from '@phosphor-icons/react';
import React from 'react';
import { Announcement } from 'types/schema';

import { Button } from 'components/base/button';
import { Checkbox } from 'components/base/checkbox';
import { DatePicker } from 'components/base/date-picker';
import { ImagesUploader } from 'components/base/images-uploader/images-uploader';
import { Input } from 'components/base/input';
import { Select } from 'components/base/select';
import { Textarea } from 'components/base/textarea';
import { TimePicker } from 'components/base/time-picker';

import * as S from './widget-announcement-create-form.styled';
import { ANNOUNCEMENT_DURATION_OPTIONS } from './constants';
import { useWidgetAnnouncementCreateFormik } from './hooks';

interface WidgetAnnouncementCreateFormProps {
  onSubmit: (data: Announcement) => void;
  onMoveBack: () => void;
}

export const WidgetAnnouncementCreateForm = ({ onSubmit, onMoveBack }: WidgetAnnouncementCreateFormProps) => {
  const { values, handleSubmit, handleChange, setFieldValue, errors } = useWidgetAnnouncementCreateFormik(onSubmit);

  const onUploadImages = (imageUrls: string[]) => {
    setFieldValue('imageUrls', imageUrls);
  };

  const onChangeStartDate = (date: Date | null) => {
    setFieldValue('startDate', date);
  };

  const onChangeStartTime = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue('startTime', e.target.value);
  };

  const onChangeDuration = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFieldValue('duration', e.target.value);
  };

  const onChangeIsManuallyRemoved = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('isManuallyRemoved', e.target.checked);
  };

  return (
    <S.CreateAnnouncementForm onSubmit={handleSubmit}>
      <Input
        type="text"
        id="header"
        name="header"
        value={values.header}
        onChange={handleChange}
        label="Announcement header"
        error={errors.header}
        placeholder="Ex. Gas supply wouldn’t work at Friday 8 AM to 5 PM"
      />
      <Textarea
        rows={5}
        id="description"
        name="description"
        value={values.description}
        onChange={handleChange}
        label="Announcement copy"
        error={errors.description}
        placeholder="Provide announcement copy here..."
      />
      <ImagesUploader
        error={Array.isArray(errors.imageUrls) ? errors.imageUrls[0] : errors.imageUrls}
        imageUrls={values.imageUrls}
        onCallback={onUploadImages}
        maxImages={1}
      />
      <span>Displaying details</span>
      <S.CreateAnnouncementDetailsWrapper>
        <S.CreateAnnouncementDetailsRow>
          <DatePicker
            error={errors.startDate}
            placeholder="Pick date"
            label="Start date"
            selected={values.startDate ? new Date(values.startDate) : null}
            onChange={onChangeStartDate}
          />
          <TimePicker error={errors.startTime} selected={values.startTime} onChange={onChangeStartTime} />
        </S.CreateAnnouncementDetailsRow>
        <Select
          value={values.duration}
          disabled={values.isManuallyRemoved}
          label="Announcement will be visible"
          onChange={onChangeDuration}
          options={ANNOUNCEMENT_DURATION_OPTIONS}
          error={errors.duration}
        />
        <Checkbox
          type="checkbox"
          label="Until removed manually"
          checked={values.isManuallyRemoved}
          onChange={onChangeIsManuallyRemoved}
        />
      </S.CreateAnnouncementDetailsWrapper>
      <S.Footer>
        <Button type="button" onClick={onMoveBack}>
          <ArrowLeft />
          Back
        </Button>
        <S.CreateAnnouncementButton type="submit">Create Announcement</S.CreateAnnouncementButton>
      </S.Footer>
    </S.CreateAnnouncementForm>
  );
};
