export const INTERVAL_OPTIONS = [
  {
    label: '3 seconds',
    value: '3',
  },
  {
    label: '5 seconds',
    value: '5',
  },
  {
    label: '15 seconds',
    value: '15',
  },
  {
    label: '30 seconds',
    value: '30',
  },
  {
    label: '45 seconds',
    value: '45',
  },
];
