export enum WidgetTypeEnum {
  NeighborhoodPerks = 'NeighborhoodPerks',
  AutomatedEventFlyerRotation = 'AutomatedEventFlyerRotation',
  Calendar = 'Calendar',
  Weather = 'Weather',
  // Note: For now this widget is not enabled in the app
  // Transportation = 'Transportation',
  RotationGallery = 'RotationGallery',
  Announcements = 'Announcements',
}

export enum SlotIdEnum {
  Slot1 = 'slot-1',
  Slot2 = 'slot-2',
  Slot3 = 'slot-3',
  Slot4 = 'slot-4',
  Slot5 = 'slot-5',
  Slot6 = 'slot-6',
}

export enum WidgetSizeEnum {
  HalfColumn = 'half-column',
  FullColumn = 'full-column',
  HorizontalColumn = 'horizontal-column',
  SquareColumn = 'square-column',
}

export interface RotatingGalleryWidgetData {
  images: string[];
  interval: string;
}

export interface AutomatedEventFlyerWidgetData {
  interval: string;
}

export interface Announcement {
  header: string;
  description: string;
  imageUrls: string[];
  startDate: string;
  startTime: string;
  duration: string;
  isManuallyRemoved: boolean;
}

export interface AnnouncementsWidgetData {
  backupImageUrl?: string;
  announcements: Announcement[];
}

export type WidgetData = RotatingGalleryWidgetData | AutomatedEventFlyerWidgetData | AnnouncementsWidgetData | null;

export type LayoutItem = {
  widget: WidgetTypeEnum | null;
  widgetId: string | null;
  slotId: SlotIdEnum;
  isCovered: boolean;
  size: WidgetSizeEnum;
  data: WidgetData;
};

export type Schema = LayoutItem[];
