import styled from 'styled-components';

import { Card } from 'components/base/card';

export const WidgetsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
`;

export const WidgetType = styled(Card)`
  cursor: pointer;
  color: ${(props) => props.theme.text.primary};
`;
