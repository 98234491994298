import { Info, MapPinLine, Users } from '@phosphor-icons/react';
import endpoints from 'endpoints';
import React from 'react';
import urljoin from 'url-join';
import { formatDateToDayMonthYear } from 'utils/format-date';
import { formatTime } from 'utils/format-time';

import { useGetBuilding } from 'api/get-building';
import { CalendarEventData } from 'api/get-calendar-events';

import * as S from './calendar-event.styled';

interface CalendarEventProps {
  data: CalendarEventData;
}

export const CalendarEvent = ({ data }: CalendarEventProps) => {
  const { data: building } = useGetBuilding();

  return (
    <S.Container>
      <S.ImageContainer>
        <S.Image src={urljoin(process.env.REACT_APP_EV_URL, endpoints.EVENT_IMAGE, data.image)} />
      </S.ImageContainer>
      <S.InfoContainer>
        <S.TopRow>
          <S.Title>{data.name}</S.Title>
          <S.Date>
            {formatDateToDayMonthYear(new Date(data.date), building?.timezone)} <S.DateDivider />{' '}
            {formatTime(data.date)}
          </S.Date>
        </S.TopRow>
        <S.BottomRow>
          <S.IconValueContainer $gridArea="location">
            <MapPinLine />
            <span>Location: {data.place}</span>
          </S.IconValueContainer>
          <S.IconValueContainer $gridArea="capacity">
            <Users />
            <span>Capacity: {data.capacity}</span>
          </S.IconValueContainer>
          <S.IconValueContainer $gridArea="description">
            <Info />
            <span>{data.description}</span>
          </S.IconValueContainer>
        </S.BottomRow>
      </S.InfoContainer>
    </S.Container>
  );
};
