import { useMutation } from 'react-query';

import { axiosInstance } from '../axiosInstance';

interface UploadFilesResponse {
  fileUrls: string[];
}

export const uploadFiles = async (files: FormData) => {
  const response = await axiosInstance.post(`/tv-app/upload-files`, files, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data as UploadFilesResponse;
};

export const useUploadFiles = () =>
  useMutation({
    mutationFn: uploadFiles,
  });
