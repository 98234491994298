import { useMemo } from 'react';
import { useAddWidgetStore } from 'stores/add-widget-store';
import { useSchemaStore } from 'stores/schema-store';
import { useShallow } from 'zustand/react/shallow';

import { getSchemaWithoutWidget } from './utils';

export const useManageWidget = (widgetId: string, handleIsWidgetOpen: (state: boolean) => void) => {
  const schema = useSchemaStore((state) => state.schema);
  const setSchema = useSchemaStore((state) => state.setSchema);
  const { setData, setEditedWidgetSlotIds, setIsOpen, setSize, setStep, setType } = useAddWidgetStore(
    useShallow((state) => ({
      ...state,
    })),
  );

  const widget = useMemo(() => schema.find((item) => item.widgetId === widgetId), [schema, widgetId]);
  const widgetSlotIds = useMemo(
    () => schema.filter((item) => item.widgetId === widgetId).map((item) => item.slotId),
    [schema, widgetId],
  );

  const onRemove = () => {
    setSchema(getSchemaWithoutWidget(schema, widgetId));
    handleIsWidgetOpen(false);
  };

  const onMove = () => {
    if (!widget) {
      return;
    }

    setEditedWidgetSlotIds(widgetSlotIds);
    setData(widget.data);
    setSize(widget.size);
    setType(widget.widget!);
    setStep('placement');
    setIsOpen(true);
    handleIsWidgetOpen(false);
  };

  const onChangeSize = () => {
    if (!widget) {
      return;
    }

    setEditedWidgetSlotIds(widgetSlotIds);
    setData(widget.data);
    setSize(widget.size);
    setType(widget.widget!);
    setStep('size');
    setIsOpen(true);
    handleIsWidgetOpen(false);
  };

  return { onRemove, onMove, onChangeSize };
};
