import { PerkData } from 'api/get-perks';

import * as S from './neighbourhood-perk.styled';
import { getImageUrl } from './utils';

interface NeighbourhoodPerkProps {
  data: PerkData;
}

export const NeighbourhoodPerk = ({ data }: NeighbourhoodPerkProps) => (
  <S.Container>
    <S.ImageContainer>
      <S.Image src={getImageUrl(data.image)} />
      {data.logo ? (
        <S.LogoContainer>
          <S.Logo src={getImageUrl(data.logo)} />
        </S.LogoContainer>
      ) : null}
    </S.ImageContainer>
    <S.InfoContainer>
      <S.Title>{data.name}</S.Title>
      <S.Description>{data.description}</S.Description>
    </S.InfoContainer>
  </S.Container>
);
