import { Schema, SlotIdEnum, WidgetSizeEnum } from 'types/schema';
import { create } from 'zustand';

interface SchemaStore {
  schema: Schema;
  setSchema: (schema: Schema) => void;
}

const initialSchema: Schema = [
  {
    widget: null,
    slotId: SlotIdEnum.Slot1,
    isCovered: false,
    size: WidgetSizeEnum.HalfColumn,
    widgetId: null,
    data: null,
  },
  {
    widget: null,
    slotId: SlotIdEnum.Slot2,
    isCovered: false,
    size: WidgetSizeEnum.HalfColumn,
    widgetId: null,
    data: null,
  },
  {
    widget: null,
    slotId: SlotIdEnum.Slot3,
    isCovered: false,
    size: WidgetSizeEnum.HalfColumn,
    widgetId: null,
    data: null,
  },
  {
    widget: null,
    slotId: SlotIdEnum.Slot4,
    isCovered: false,
    size: WidgetSizeEnum.HalfColumn,
    widgetId: null,
    data: null,
  },
  {
    widget: null,
    widgetId: null,
    slotId: SlotIdEnum.Slot5,
    isCovered: false,
    size: WidgetSizeEnum.HalfColumn,
    data: null,
  },
  {
    widget: null,
    widgetId: null,
    slotId: SlotIdEnum.Slot6,
    isCovered: false,
    size: WidgetSizeEnum.HalfColumn,
    data: null,
  },
];

export const useSchemaStore = create<SchemaStore>((set) => ({
  schema: initialSchema,
  setSchema: (schema) => set({ schema }),
}));
