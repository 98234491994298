import { ArrowLeft } from '@phosphor-icons/react';
import { Tooltip } from 'react-tooltip';
import { useAddWidgetService } from 'services/add-widget-service';
import { WIDGET_FLOWS_BY_TYPE_MAP } from 'services/constants';
import { useAddWidgetStore } from 'stores/add-widget-store';
import { useSchemaStore } from 'stores/schema-store';
import { WidgetSizeEnum, WidgetTypeEnum } from 'types/schema';
import { getWidgetSizeIcon } from 'utils/get-widget-size-icon';
import { getWidgetSizeLabel } from 'utils/get-widget-size-label';
import { getWidgetSizesByType } from 'utils/get-widget-sizes-by-type';
import { isWidgetSizePlaceable } from 'utils/is-widget-size-placeable';

import { Modal } from 'components/base/modal';

import * as S from './add-widget-size-modal.styled';

interface AddWidgetSizeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectWidgetSize: (size: WidgetSizeEnum) => void;
  type: WidgetTypeEnum;
}

export const AddWidgetSizeModal = ({ isOpen, onClose, type, onSelectWidgetSize }: AddWidgetSizeModalProps) => {
  const { moveToPreviousStep } = useAddWidgetService();
  const schema = useSchemaStore((state) => state.schema);
  const editedWidgetSlotIds = useAddWidgetStore((state) => state.editedWidgetSlotIds);

  return (
    <Modal hideScroll onClose={onClose} size="large" isOpen={isOpen} header="Select widget size">
      <S.Container>
        <S.SizesWrapper>
          {getWidgetSizesByType(type).map((size) => {
            const isPlaceable = isWidgetSizePlaceable(size, schema, editedWidgetSlotIds);

            return (
              <S.SizeCard
                data-tooltip-id={size}
                data-tooltip-content="Insufficient available space. Please relocate or remove existing widgets to ensure adequate room for selecting the desired size."
                data-tooltip-place="top"
                key={size}
                onClick={isPlaceable ? () => onSelectWidgetSize(size) : undefined}
                disabled={!isPlaceable}
              >
                {getWidgetSizeIcon(size)}
                {getWidgetSizeLabel(size)}
                {isPlaceable ? null : (
                  <Tooltip
                    style={{
                      maxWidth: '250px',
                    }}
                    id={size}
                  />
                )}
              </S.SizeCard>
            );
          })}
        </S.SizesWrapper>
        <S.BackButton onClick={() => moveToPreviousStep(WIDGET_FLOWS_BY_TYPE_MAP[type])}>
          <ArrowLeft />
          Back
        </S.BackButton>
      </S.Container>
    </Modal>
  );
};
