export const ANNOUNCEMENT_DURATION_OPTIONS = [
  {
    label: '12h',
    value: '12',
  },
  {
    label: '24h',
    value: '24',
  },
];
