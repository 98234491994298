import styled from 'styled-components';

export const Button = styled.button`
  background-color: ${(props) => props.theme.background.button};
  color: ${(props) => props.theme.text.button};
  border-radius: 4px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.background.buttonHover};
  }

  &:disabled {
    background-color: ${(props) => props.theme.background.buttonHover};
    cursor: not-allowed;
  }
`;

export const ModalButton = styled(Button)`
  background-color: ${(props) => props.theme.background.buttonModal};
`;
