import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { getLocationIdFromUrl } from 'utils/get-location-id-from-url';

import { NoLocationPage } from 'components/no-location-page';

import { Home } from './components/home';
import { useSettingsStore } from './stores/settings-store';
import { generateTheme } from './utils/generate-theme';

const queryClient = new QueryClient();

const App = () => {
  const settings = useSettingsStore((state) => state.settings);
  const locationId = getLocationIdFromUrl(window.location.href);

  if (!locationId) {
    return <NoLocationPage />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={generateTheme(settings.isDarkMode)}>
        <Home />
        <ToastContainer theme="dark" />
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
