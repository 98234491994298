import React from 'react';

import * as S from './checkbox.styled';
import { Label } from '../label';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Checkbox = ({ label, ...props }: CheckboxProps) => (
  <S.CheckboxWrapper>
    <S.Checkbox type="checkbox" {...props} />
    {label && <Label htmlFor={props.name}>{label}</Label>}
  </S.CheckboxWrapper>
);
