import { useQuery } from 'react-query';
import { getLocationIdFromUrl } from 'utils/get-location-id-from-url';

import { axiosInstance } from '../axiosInstance';

export interface Settings {
  isLogoHidden: boolean;
  isDarkMode: boolean;
  customLogoUrl: string;
  customLogoDarkUrl: string;
}

export const getSettingsQueryKey = (locationId: string) => ['settings', locationId];

const getSettings = async (locationId: string) => {
  const { data } = await axiosInstance.get<Settings>(`/tv-app/locationId/${locationId}/settings`);
  return data;
};

export const useGetSettings = () => {
  const locationId = getLocationIdFromUrl(window.location.href);

  return useQuery({
    queryKey: getSettingsQueryKey(locationId),
    queryFn: () => getSettings(locationId),
  });
};
