import { AddWidgetStep } from 'stores/add-widget-store';
import { WidgetSizeEnum, WidgetTypeEnum } from 'types/schema';

// Note: This is flow for every widget type in order
export const WIDGET_FLOWS_BY_TYPE_MAP: { [key in WidgetTypeEnum]: AddWidgetStep[] } = {
  [WidgetTypeEnum.NeighborhoodPerks]: ['type', 'size', 'placement'],
  [WidgetTypeEnum.AutomatedEventFlyerRotation]: ['type', 'data', 'size', 'placement'],
  [WidgetTypeEnum.Calendar]: ['type', 'size', 'placement'],
  [WidgetTypeEnum.Weather]: ['type', 'size', 'placement'],
  // Note: For now this widget is not enabled in the app
  // [WidgetTypeEnum.Transportation]: ['type', 'size', 'placement'],
  [WidgetTypeEnum.RotationGallery]: ['type', 'data', 'size', 'placement'],
  [WidgetTypeEnum.Announcements]: ['type', 'data', 'size', 'placement'],
};

// Note: This is flow for every widget type in order
export const WIDGET_SIZES_BY_TYPE_MAP: { [key in WidgetTypeEnum]: WidgetSizeEnum[] } = {
  [WidgetTypeEnum.NeighborhoodPerks]: [
    WidgetSizeEnum.FullColumn,
    WidgetSizeEnum.HalfColumn,
    WidgetSizeEnum.HorizontalColumn,
    WidgetSizeEnum.SquareColumn,
  ],
  [WidgetTypeEnum.AutomatedEventFlyerRotation]: [WidgetSizeEnum.FullColumn],
  [WidgetTypeEnum.Calendar]: [
    WidgetSizeEnum.FullColumn,
    WidgetSizeEnum.HalfColumn,
    WidgetSizeEnum.HorizontalColumn,
    WidgetSizeEnum.SquareColumn,
  ],
  [WidgetTypeEnum.Weather]: [WidgetSizeEnum.HalfColumn],
  // Note: For now this widget is not enabled in the app
  // [WidgetTypeEnum.Transportation]: [
  //   WidgetSizeEnum.FullColumn,
  //   WidgetSizeEnum.HalfColumn,
  //   WidgetSizeEnum.HorizontalColumn,
  //   WidgetSizeEnum.SquareColumn,
  // ],
  [WidgetTypeEnum.RotationGallery]: [
    WidgetSizeEnum.FullColumn,
    WidgetSizeEnum.HalfColumn,
    WidgetSizeEnum.HorizontalColumn,
    WidgetSizeEnum.SquareColumn,
  ],
  [WidgetTypeEnum.Announcements]: [
    WidgetSizeEnum.FullColumn,
    WidgetSizeEnum.HalfColumn,
    WidgetSizeEnum.HorizontalColumn,
  ],
};
