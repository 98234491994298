import endpoints from 'endpoints';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { HashLoader } from 'react-spinners';
import { useSchemaStore } from 'stores/schema-store';
import { AutomatedEventFlyerWidgetData } from 'types/schema';
import urljoin from 'url-join';
import { getMsFromSeconds } from 'utils/get-ms-from-seconds';

import { useGetPdfs } from 'api/get-pdfs';

import { WidgetAutomatedEventFlyerDataModal } from 'components/modals/widget-automated-event-flyer-data-modal';

import * as S from './widget-pdfs.styled';
import { WidgetDropdown } from '../widget-dropdown';

interface WidgetPdfsProps {
  data: AutomatedEventFlyerWidgetData;
  widgetId: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const WidgetPdfs = ({ widgetId, data }: WidgetPdfsProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { data: pdfs, isLoading } = useGetPdfs();
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);

  const schema = useSchemaStore((state) => state.schema);
  const setSchema = useSchemaStore((state) => state.setSchema);

  useEffect(() => {
    if (!pdfs) {
      return;
    }

    const changePdf = setInterval(() => {
      setCurrentPdfIndex((prevIndex) => {
        if (prevIndex === pdfs.length - 1) {
          return 0;
        }

        return prevIndex + 1;
      });
    }, getMsFromSeconds(Number(data.interval)));

    return () => clearInterval(changePdf);
  }, [pdfs, data.interval]);

  const renderPdfs = () => {
    if (isLoading) {
      return (
        <S.Loading>
          <HashLoader />
        </S.Loading>
      );
    }

    if (!pdfs) {
      return <S.NoPdfsWrapper>No pdfs</S.NoPdfsWrapper>;
    }

    return (
      <S.PdfsList $activeIndex={currentPdfIndex}>
        {pdfs.map((pdf, i) => (
          <S.PdfWrapper key={pdf.id} className="pdfWrapper">
            <S.PdfDocument
              className="pdfDocument"
              file={urljoin(process.env.REACT_APP_EV_URL, endpoints.EVENT_IMAGE, pdfs?.[i]?.file)}
              loading={<HashLoader />}
            >
              <S.PdfPage className="pdfPage" pageNumber={1} renderMode="canvas" renderAnnotationLayer={false} />
            </S.PdfDocument>
          </S.PdfWrapper>
        ))}
      </S.PdfsList>
    );
  };

  const onEdit = (data: AutomatedEventFlyerWidgetData) => {
    const newSchema = schema.map((item) => {
      if (item.widgetId === widgetId) {
        return {
          ...item,
          data,
        };
      }

      return item;
    });

    setSchema(newSchema);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <S.PdfsWrapper>{renderPdfs()}</S.PdfsWrapper>
      <WidgetDropdown widgetId={widgetId} onEdit={() => setIsEditModalOpen(true)} />
      {isEditModalOpen ? (
        <WidgetAutomatedEventFlyerDataModal
          data={data}
          isOpen
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onEdit}
          submitText="Save"
        />
      ) : null}
    </>
  );
};
