import React from 'react';

import * as S from './select.styled';
import { Label } from '../label';

interface Option {
  value: string | number;
  label: string;
}

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  options: Option[];
  error?: string;
}

export const Select = ({ label, options, error, disabled, ...props }: SelectProps) => (
  <S.SelectWrapper $disabled={disabled}>
    {label && <Label htmlFor={props.name}>{label}</Label>}
    <S.Select $error={!!error} {...props} disabled={disabled}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </S.Select>
    {error && <S.Error>{error}</S.Error>}
  </S.SelectWrapper>
);
