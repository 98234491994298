import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';

export const ImagesUploaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ImagesUploaderContainer = styled.div<{ $error: boolean }>`
  display: flex;
  gap: 4px;
  background-color: ${(props) =>
    props.$error ? hexToRgba(props.theme.error, 0.2) : props.theme.background.imageUploader};
  color: ${(props) => props.theme.text};
  border: 1px dashed ${(props) => (props.$error ? props.theme.error : props.theme.border.form)};
  min-height: 100px;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  overflow: hidden;
`;

export const ImagePreviewWrapper = styled.div`
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  width: 100px;
  aspect-ratio: 1/1;
  position: relative;
`;

export const ImagePreview = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const RemovePhotoButton = styled.button`
  width: 20px;
  height: 20px;
  color: ${(props) => props.theme.error};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const AddPhotoButton = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.text.primary};
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 16px;
  align-self: flex-start;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const AddPhotoPlusIcon = styled.div<{ disabled: boolean }>`
  width: 32px;
  height: 32px;
  aspect-ratio: 1;
  background-color: ${(props) => props.theme.background.imageUploaderButton};
  border-radius: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

export const Error = styled.span`
  color: ${(props) => props.theme.error};
  font-size: 12px;
`;
