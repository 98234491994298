import { ArrowsClockwise, Check, Download, Info, X } from '@phosphor-icons/react';
import React from 'react';
import { Announcement, AnnouncementsWidgetData } from 'types/schema';
import { formatDateToYearMonthDayDate } from 'utils/format-date';
import { colors } from 'utils/generate-theme';

import { Badge } from 'components/base/badge';
import { ModalButton } from 'components/base/button';
import { Modal } from 'components/base/modal';

import { useUploadPhotos } from 'hooks/use-upload-photos';

import * as S from './widget-announcements-data-modal.styled';
import { useWidgetAnnouncementsDataFormik } from './hooks';
import { WidgetAnnouncementCreateForm } from './widget-announcement-create-form';

interface WidgetAnnouncementsDataModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: AnnouncementsWidgetData) => void;
  submitText: string;
  data?: AnnouncementsWidgetData | null;
  badge?: string;
}

export const WidgetAnnouncementsDataModal = ({
  isOpen,
  onClose,
  onSubmit,
  submitText,
  data,
  badge,
}: WidgetAnnouncementsDataModalProps) => {
  const { values, handleSubmit, setFieldValue, errors } = useWidgetAnnouncementsDataFormik(
    data ?? { backupImageUrl: '', announcements: [] },
    onSubmit,
  );

  const [step, setStep] = React.useState<'announcements' | 'create'>('announcements');

  const onAddAnnouncement = (announcement: Announcement) => {
    setFieldValue('announcements', [...values.announcements, announcement]);
    setStep('announcements');
  };

  const onRemoveAnnouncement = (index: number) => {
    const newAnnouncements = values.announcements.filter((_, i) => i !== index);
    setFieldValue('announcements', newAnnouncements);
  };

  const onUploadBackupImage = (imageUrls: string[]) => {
    setFieldValue('backupImageUrl', imageUrls[0]);
  };

  const { getInputProps, getRootProps, isLoading } = useUploadPhotos(onUploadBackupImage, 1);

  const renderModalHeader = () => {
    if (step === 'create') {
      return 'Create Announcement';
    }

    return 'Widget Announcements';
  };

  const renderModalFooter = () => {
    if (step === 'create') {
      return null;
    }

    return (
      <ModalButton type="submit" onClick={() => handleSubmit()}>
        {submitText}
      </ModalButton>
    );
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} header={renderModalHeader()} footer={renderModalFooter()}>
      {step === 'create' ? (
        <WidgetAnnouncementCreateForm onMoveBack={() => setStep('announcements')} onSubmit={onAddAnnouncement} />
      ) : (
        <S.AnnouncementsForm>
          {values.announcements.length >= 1 ? (
            values.announcements.map((announcement, index) => (
              <S.AnnouncementItem key={announcement.header}>
                <S.AnnouncementHeader>{announcement.header}</S.AnnouncementHeader>
                <S.AnnouncementDescription>{announcement.description}</S.AnnouncementDescription>
                <S.AnnouncementRow>
                  <span>Start date: {formatDateToYearMonthDayDate(announcement.startDate)}</span>
                  <span>Visible for: {announcement.isManuallyRemoved ? '-' : `${announcement.duration} hours`}</span>
                </S.AnnouncementRow>
                <S.AnnouncementActions>
                  <S.DeleteAnnouncementButton onClick={() => onRemoveAnnouncement(index)}>
                    <X fill={colors.error} />
                    Delete
                  </S.DeleteAnnouncementButton>
                </S.AnnouncementActions>
              </S.AnnouncementItem>
            ))
          ) : (
            <S.NoAnnouncementsWrapper
              $error={Array.isArray(errors.announcements) ? errors.announcements.length > 1 : !!errors.announcements}
            >
              <span>No announcements</span>
              <span>Click the button below to add new item</span>
            </S.NoAnnouncementsWrapper>
          )}
          {Array.isArray(errors.announcements) && errors.announcements.length > 1 ? (
            <S.NoAnnouncementsError>{errors.announcements[0]}</S.NoAnnouncementsError>
          ) : null}
          {errors.announcements && !Array.isArray(errors.announcements) ? (
            <S.NoAnnouncementsError>{errors.announcements}</S.NoAnnouncementsError>
          ) : null}
          <S.AddAnnouncementButton onClick={() => setStep('create')}>Add announcement</S.AddAnnouncementButton>
          <S.UploadBackupImageWrapper {...getRootProps()} disabled={isLoading}>
            {values.backupImageUrl ? (
              <>
                <ArrowsClockwise />
                Replace Backup Image
              </>
            ) : (
              <>
                <Download />
                Upload Backup Image
              </>
            )}
            <input {...getInputProps()} />
          </S.UploadBackupImageWrapper>
          <S.BackupImageInfoWrapper>
            {values.backupImageUrl && (
              <S.BackupImageSuccessMessage>
                <Check /> Your file has been uploaded
              </S.BackupImageSuccessMessage>
            )}
            {badge ? <Badge icon={<Info />}>{badge}</Badge> : null}
            <S.BackupImageInfo>*The image will be displayed in case announcements expire</S.BackupImageInfo>
          </S.BackupImageInfoWrapper>
        </S.AnnouncementsForm>
      )}
    </Modal>
  );
};
