import { useEffect, useMemo, useRef, useState } from 'react';
import { HashLoader } from 'react-spinners';
import { WidgetSizeEnum } from 'types/schema';

import { CalendarEventData, useGetCalendarEvents } from 'api/get-calendar-events';

import * as S from './widget-calendar.styled';
import { WidgetDropdown } from '../widget-dropdown';
import { CalendarEvent } from './calendar-event';
import { CALENDAR_CAROUSEL_SLIDE_DURATION } from './constants';
import { getSlidesCountBasedOnWidgetSize } from './utils';

interface WidgetCalendarProps {
  widgetSize: WidgetSizeEnum;
  widgetId: string;
}

export const WidgetCalendar = ({ widgetId, widgetSize }: WidgetCalendarProps) => {
  const { data: calendarEvents, isLoading } = useGetCalendarEvents();
  const [eventsToRender, setEventsToRender] = useState<CalendarEventData[]>([]);
  const currentSlideRef = useRef(-1);

  const displayedEventsCount = useMemo(() => getSlidesCountBasedOnWidgetSize(widgetSize), [widgetSize]);

  const totalSlides = useMemo(() => {
    if (!calendarEvents) return 0;
    return Math.ceil(calendarEvents.length / displayedEventsCount);
  }, [calendarEvents, displayedEventsCount]);

  const updateEventsToRender = () => {
    if (!calendarEvents || calendarEvents.length === 0) return;
    currentSlideRef.current = (currentSlideRef.current + 1) % totalSlides;
    const startIndex = currentSlideRef.current * displayedEventsCount;
    const newEvents = calendarEvents.slice(startIndex, startIndex + displayedEventsCount);
    setEventsToRender(newEvents);
  };

  useEffect(() => {
    if (calendarEvents) {
      updateEventsToRender();
    }

    const interval = setInterval(updateEventsToRender, CALENDAR_CAROUSEL_SLIDE_DURATION);
    return () => clearInterval(interval);
  }, [calendarEvents, displayedEventsCount, totalSlides]);

  const renderPerks = () => {
    if (isLoading) {
      return (
        <S.Loading>
          <HashLoader />
        </S.Loading>
      );
    }

    if (!calendarEvents || calendarEvents.length === 0) {
      return <S.NoEventsWrapper>There are no events in the calendar</S.NoEventsWrapper>;
    }

    return eventsToRender.map((event) => <CalendarEvent key={event.name} data={event} />);
  };

  const renderSlideIndicators = () => (
    <S.SlideIndicators>
      {Array.from({ length: totalSlides }).map((_, index) => (
        <S.SlideIndicator key={index} $active={index === currentSlideRef.current} />
      ))}
    </S.SlideIndicators>
  );

  return (
    <S.WidgetWrapper>
      <S.EventsWrapper $widgetSize={widgetSize}>{renderPerks()}</S.EventsWrapper>
      {renderSlideIndicators()}
      <WidgetDropdown widgetId={widgetId} />
    </S.WidgetWrapper>
  );
};
