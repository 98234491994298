import { ArrowLeft, Plus } from '@phosphor-icons/react';
import { useAddWidgetService } from 'services/add-widget-service';
import { WIDGET_FLOWS_BY_TYPE_MAP } from 'services/constants';
import { useAddWidgetStore } from 'stores/add-widget-store';
import { useIsEditMode } from 'stores/edit-mode-enum';
import {
  AnnouncementsWidgetData,
  AutomatedEventFlyerWidgetData,
  LayoutItem,
  RotatingGalleryWidgetData,
  Schema,
  SlotIdEnum,
  WidgetSizeEnum,
  WidgetTypeEnum,
} from 'types/schema';
import { colors } from 'utils/generate-theme';

import { WidgetCalendar } from 'components/widgets/widget-calendar';
import { WidgetNeighbourhoodPerks } from 'components/widgets/widget-neighbourhood-perks';
import { WidgetPdfs } from 'components/widgets/widget-pdfs';
import { WidgetWeather } from 'components/widgets/widget-weather';

import * as S from './layout-editor.styled';
import { WidgetAnnouncements } from '../widgets/widget-announcements';
import { WidgetRotatingGallery } from '../widgets/widget-rotating-gallery';
import { LayoutEditorFullColumnPicker } from './layout-editor-full-column-picker';
import { LayoutEditorHalfColumnPicker } from './layout-editor-half-column-picker';
import { LayoutEditorHorizontalColumnPicker } from './layout-editor-horizontal-column-picker';
import { LayoutEditorSquareColumnPicker } from './layout-editor-square-column-picker';
import { generateGridArea, generateGridTemplateArea } from './utils';

interface LayoutEditorProps {
  schema: Schema;
  isPlacementMode: boolean;
  editModeSize: WidgetSizeEnum | null;
  onSelectPlacement: (slotIds: SlotIdEnum[]) => void | undefined;
}

export const LayoutEditor = ({ schema, isPlacementMode, editModeSize, onSelectPlacement }: LayoutEditorProps) => {
  const setIsOpen = useAddWidgetStore((state) => state.setIsOpen);
  const isOpen = useAddWidgetStore((state) => state.isOpen);
  const step = useAddWidgetStore((state) => state.step);
  const type = useAddWidgetStore((state) => state.type);
  const { onClose, moveToPreviousStep } = useAddWidgetService();
  const [isEditModeMode] = useIsEditMode();

  const renderWidget = (layoutItem: LayoutItem) => {
    if (!layoutItem || !layoutItem.widget) {
      if (!isEditModeMode) {
        return null;
      }

      return (
        <S.PlusButton onClick={() => setIsOpen(true)}>
          <Plus color={colors.plusIcon} width="50%" height="50%" />
        </S.PlusButton>
      );
    }

    switch (layoutItem.widget) {
      case WidgetTypeEnum.Weather:
        return <WidgetWeather widgetId={layoutItem.widgetId as string} />;
      case WidgetTypeEnum.RotationGallery:
        return (
          <WidgetRotatingGallery
            widgetId={layoutItem.widgetId as string}
            data={layoutItem.data as RotatingGalleryWidgetData}
          />
        );
      case WidgetTypeEnum.Calendar:
        return <WidgetCalendar widgetSize={layoutItem.size} widgetId={layoutItem.widgetId as string} />;
      case WidgetTypeEnum.Announcements:
        return (
          <WidgetAnnouncements
            widgetSize={layoutItem.size}
            widgetId={layoutItem.widgetId as string}
            data={layoutItem.data as AnnouncementsWidgetData}
          />
        );
      case WidgetTypeEnum.AutomatedEventFlyerRotation:
        return (
          <WidgetPdfs
            widgetId={layoutItem.widgetId as string}
            data={layoutItem.data as AutomatedEventFlyerWidgetData}
          />
        );
      case WidgetTypeEnum.NeighborhoodPerks:
        return <WidgetNeighbourhoodPerks widgetSize={layoutItem.size} widgetId={layoutItem.widgetId as string} />;
      // Note: For now this widget is not enabled in the app
      // case WidgetTypeEnum.Transportation:
      // return <WidgetTransportation widgetId={layoutItem.widgetId as string} />;
      default:
        return layoutItem.widget;
    }
  };

  const renderEditModePlacementView = () => {
    switch (editModeSize) {
      case WidgetSizeEnum.FullColumn:
        return <LayoutEditorFullColumnPicker onSelectPlacement={onSelectPlacement} />;
      case WidgetSizeEnum.HalfColumn:
        return <LayoutEditorHalfColumnPicker onSelectPlacement={onSelectPlacement} />;
      case WidgetSizeEnum.HorizontalColumn:
        return <LayoutEditorHorizontalColumnPicker onSelectPlacement={onSelectPlacement} />;
      case WidgetSizeEnum.SquareColumn:
        return <LayoutEditorSquareColumnPicker onSelectPlacement={onSelectPlacement} />;
      default:
        return null;
    }
  };

  if (isPlacementMode && editModeSize) {
    const showPlacementView = isOpen && step === 'placement';

    return (
      <>
        <S.EditModePlacementViewWrapper>
          {showPlacementView ? <S.PlaceTheWidgetHeader>Place the Widget</S.PlaceTheWidgetHeader> : null}
          {renderEditModePlacementView()}
          {showPlacementView ? (
            <S.ManageButtonsWrapper>
              <S.ManageButton onClick={() => moveToPreviousStep(WIDGET_FLOWS_BY_TYPE_MAP[type])}>
                <ArrowLeft />
                Back
              </S.ManageButton>
              <S.ManageButton onClick={onClose}>Cancel</S.ManageButton>
            </S.ManageButtonsWrapper>
          ) : null}
        </S.EditModePlacementViewWrapper>
        {showPlacementView ? <S.LayoutEditorOverlay /> : null}
      </>
    );
  }

  return (
    <S.LayoutEditor $gridTemplateAreas={generateGridTemplateArea(schema)}>
      {schema.map((item) => (
        <S.LayoutItem
          $widget={item.widget}
          $gridArea={generateGridArea(item)}
          key={`${item.slotId}-${generateGridArea(item)}`}
        >
          {renderWidget(item)}
        </S.LayoutItem>
      ))}
    </S.LayoutEditor>
  );
};
