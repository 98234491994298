import { WidgetSizeEnum } from 'types/schema';

import { widgetSizeIcons } from './assets';

export const getWidgetSizeIcon = (size: WidgetSizeEnum) => {
  switch (size) {
    case WidgetSizeEnum.FullColumn:
      return <widgetSizeIcons.FullColumn />;
    case WidgetSizeEnum.HalfColumn:
      return <widgetSizeIcons.HalfColumn />;
    case WidgetSizeEnum.HorizontalColumn:
      return <widgetSizeIcons.HorizontalColumn />;
    case WidgetSizeEnum.SquareColumn:
      return <widgetSizeIcons.SquareColumn />;
    default:
      return null;
  }
};
