import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';
import { colors } from 'utils/generate-theme';

export const LayoutEditorSlotPicker = styled.div<{ $disabled: boolean; $gridArea?: string }>`
  background-color: ${(props) => props.theme.background.modal};
  color: ${(props) => props.theme.text.secondary};
  box-shadow: 0 0 10px ${hexToRgba(colors.black, 0.1)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  grid-area: ${(props) => props.$gridArea};
  padding: 10%;
  text-align: center;
`;
