import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';
import { WidgetTypeEnum } from 'types/schema';
import { colors } from 'utils/generate-theme';

import { Button } from '../base/button';
import { getLayoutItemBorder } from './utils';

export const LayoutEditor = styled.div<{ $gridTemplateAreas: string }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2vh;
  width: 100%;
  height: 100%;
  grid-template-areas: ${(props) => props.$gridTemplateAreas};
`;

export const LayoutItem = styled.div<{ $gridArea: string; $widget: WidgetTypeEnum | null }>`
  background-color: transparent;
  border: ${(props) => getLayoutItemBorder(props.$widget, props.theme.border.primary)};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: ${(props) => props.$gridArea};
  position: relative;
  overflow: hidden;
`;

export const PlusButton = styled.button`
  border: none;
  border-radius: 100%;
  border: 1px solid #828282;
  cursor: pointer;
  width: 20%;
  aspect-ratio: 1/1;
  background-color: transparent;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) => props.theme.background.plusButtonHover};
  }
`;

export const LayoutEditorOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${hexToRgba(colors.black, 0.5)};
  z-index: 10;
`;

export const EditModePlacementViewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 20;
`;

export const PlaceTheWidgetHeader = styled.header`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const ManageButtonsWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export const ManageButton = styled(Button)`
  background-color: ${(props) => (props.theme.isDarkMode ? props.theme.background.modal : 'white')};
  color: ${(props) => (props.theme.isDarkMode ? 'white' : props.theme.text.primary)};
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;
