import { WidgetSizeEnum } from 'types/schema';

export const getBadgeLabelByWidgetSize = (widgetSize?: WidgetSizeEnum) => {
  switch (widgetSize) {
    case WidgetSizeEnum.FullColumn:
      return 'Recommended Aspect Ratio 2:3';
    case WidgetSizeEnum.HalfColumn:
      return 'Recommended Aspect Ratio 6:4.5';
    case WidgetSizeEnum.HorizontalColumn:
      return 'Recommended Aspect Ratio 16.5:6';
    case WidgetSizeEnum.SquareColumn:
      return 'Recommended Aspect Ratio 5.5:4';
    default:
      return '';
  }
};
