import React from 'react';
import { useAddWidgetStore } from 'stores/add-widget-store';
import { useSchemaStore } from 'stores/schema-store';
import { SlotIdEnum } from 'types/schema';

import * as S from './layout-editor-full-column-picker.styled';
import { LayoutEditorSlotPicker } from '../layout-editor-slot-picker';
import { isSlotCovered } from '../utils';

interface LayoutEditorFullColumnPickerProps {
  onSelectPlacement: (slotIds: SlotIdEnum[]) => void;
}

export const FULL_COLUMN_PLACEMENT_OPTIONS = [
  [SlotIdEnum.Slot1, SlotIdEnum.Slot4],
  [SlotIdEnum.Slot2, SlotIdEnum.Slot5],
  [SlotIdEnum.Slot3, SlotIdEnum.Slot6],
];

export const LayoutEditorFullColumnPicker = ({ onSelectPlacement }: LayoutEditorFullColumnPickerProps) => {
  const schema = useSchemaStore((state) => state.schema);
  const editedWidgetSlotIds = useAddWidgetStore((state) => state.editedWidgetSlotIds);

  return (
    <S.LayoutEditorFullColumnPicker>
      {FULL_COLUMN_PLACEMENT_OPTIONS.map((slotIds) => {
        const isCovered = isSlotCovered(schema, slotIds, editedWidgetSlotIds);

        return (
          <LayoutEditorSlotPicker
            disabled={isCovered}
            key={slotIds[0]}
            onSelectPlacement={() => onSelectPlacement(slotIds)}
          />
        );
      })}
    </S.LayoutEditorFullColumnPicker>
  );
};
