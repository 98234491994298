import { useQuery } from 'react-query';
import { Schema } from 'types/schema';
import { getLocationIdFromUrl } from 'utils/get-location-id-from-url';

import { axiosInstance } from '../axiosInstance';

export interface GetSchemaResponse {
  id: string;
  buildingId: number;
  schema: string;
}

export interface GetSchema {
  schema: Schema;
  buildingId: number;
  id: string;
}

const getSchema = async (locationId: string) => {
  const { data } = await axiosInstance.get<GetSchemaResponse>(`/tv-app/locationId/${locationId}/schema`);
  return { ...data, schema: JSON.parse(data.schema) } as GetSchema;
};

export const useGetSchema = () => {
  const locationId = getLocationIdFromUrl(window.location.href);

  return useQuery({
    queryKey: 'schema',
    queryFn: () => getSchema(locationId),
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });
};
