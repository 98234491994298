import { useQuery } from 'react-query';
import { useBuildingIdAtom } from 'stores/building-id-atom';

import { axiosInstance } from '../axiosInstance';

export interface CalendarEventData {
  name: string;
  description?: string;
  place?: string;
  capacity: number;
  taken: number;
  cost?: number;
  clientAvatars: string[];
  date: string;
  image: string;
  timezone: string;
}

export interface CalendarGroupedEvents {
  date: string;
  calendarEvents: CalendarEventData[];
}

const getCalendarEventsKey = (buildingId: number | null) => ['calendar-events', buildingId];

const getCalendarEvents = async (buildingId: number | null) => {
  const { data } = await axiosInstance.get<CalendarGroupedEvents[]>(`/training-for-tv/${buildingId}?limit=10`);
  return data.flatMap((entry) => entry.calendarEvents.map((event) => event));
};

export const useGetCalendarEvents = () => {
  const [buildingId] = useBuildingIdAtom();

  return useQuery({
    enabled: !!buildingId,
    queryKey: getCalendarEventsKey(buildingId),
    queryFn: () => getCalendarEvents(buildingId),
  });
};
