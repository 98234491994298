import React, { useEffect, useMemo, useState } from 'react';
import { useSchemaStore } from 'stores/schema-store';
import { RotatingGalleryWidgetData } from 'types/schema';
import { getBadgeLabelByWidgetSize } from 'utils/get-image-size-badge';
import { getMsFromSeconds } from 'utils/get-ms-from-seconds';

import { WidgetRotationGalleryDataModal } from 'components/modals/widget-rotation-gallery-data-modal';

import * as S from './widget-rotating-gallery.styled';
import { WidgetDropdown } from '../widget-dropdown';

interface WidgetRotatingGalleryProps {
  data: RotatingGalleryWidgetData;
  widgetId: string;
}

export const WidgetRotatingGallery = ({ data, widgetId }: WidgetRotatingGalleryProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const schema = useSchemaStore((state) => state.schema);
  const setSchema = useSchemaStore((state) => state.setSchema);

  const widget = useMemo(() => schema.find((item) => item.widgetId === widgetId), [schema, widgetId]);

  useEffect(() => {
    const changeImage = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        if (prevIndex === data.images.length - 1) {
          return 0;
        }

        return prevIndex + 1;
      });
    }, getMsFromSeconds(Number(data.interval)));

    return () => clearInterval(changeImage);
  }, [data.interval, data.images]);

  const onEdit = (data: RotatingGalleryWidgetData) => {
    const newSchema = schema.map((item) => {
      if (item.widgetId === widgetId) {
        return {
          ...item,
          data,
        };
      }

      return item;
    });

    setSchema(newSchema);
    setIsEditModalOpen(false);
  };

  return (
    <>
      <S.ImagesList $activeIndex={currentImageIndex}>
        {data.images.map((image) => (
          <S.ImageWrapper key={image}>
            <S.Image src={image} alt="Image" />
          </S.ImageWrapper>
        ))}
      </S.ImagesList>
      <WidgetDropdown widgetId={widgetId} onEdit={() => setIsEditModalOpen(true)} />
      {isEditModalOpen ? (
        <WidgetRotationGalleryDataModal
          data={data}
          badge={getBadgeLabelByWidgetSize(widget?.size)}
          isOpen
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={onEdit}
          submitText="Save"
        />
      ) : null}
    </>
  );
};
