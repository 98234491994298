import { Schema, SlotIdEnum, WidgetSizeEnum } from 'types/schema';

import { FULL_COLUMN_PLACEMENT_OPTIONS } from 'components/layout-editor/layout-editor-full-column-picker/layout-editor-full-column-picker';
import { HORIZONTAL_COLUMN_PLACEMENT_OPTIONS } from 'components/layout-editor/layout-editor-horizontal-column-picker/layout-editor-horizontal-column-picker';
import { SQUARE_COLUMN_PLACEMENT_OPTIONS } from 'components/layout-editor/layout-editor-square-column-picker/layout-editor-square-column-picker';
import { isSlotCovered } from 'components/layout-editor/utils';

export const isWidgetSizePlaceable = (size: WidgetSizeEnum, schema: Schema, omittedSlotIds: SlotIdEnum[]) => {
  switch (size) {
    case WidgetSizeEnum.FullColumn:
      return FULL_COLUMN_PLACEMENT_OPTIONS.some((slotIds) => !isSlotCovered(schema, slotIds, omittedSlotIds));
    case WidgetSizeEnum.HalfColumn:
      return schema.some((layoutItem) => !isSlotCovered(schema, [layoutItem.slotId], omittedSlotIds));
    case WidgetSizeEnum.HorizontalColumn:
      return HORIZONTAL_COLUMN_PLACEMENT_OPTIONS.some((slotIds) => !isSlotCovered(schema, slotIds, omittedSlotIds));
    case WidgetSizeEnum.SquareColumn:
      return SQUARE_COLUMN_PLACEMENT_OPTIONS.some((slotIds) => !isSlotCovered(schema, slotIds, omittedSlotIds));
    default:
      return false;
  }
};
