import hexToRgba from 'hex-to-rgba';
import styled from 'styled-components';

import { Button, ModalButton } from 'components/base/button';

export const AnnouncementsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
`;

export const NoAnnouncementsWrapper = styled.div<{ $error: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${(props) => props.theme.text.secondary};
  padding: 10px;
  border: ${(props) => (props.$error ? `1px solid ${props.theme.error}` : '1px solid transparent')};
  border-style: dashed;
`;

export const NoAnnouncementsError = styled.p`
  color: ${(props) => props.theme.error};
  padding: 0;
  margin: 0;
`;

export const AnnouncementsList = styled.div``;

export const AnnouncementItem = styled.div`
  border: 1px solid ${(props) => props.theme.border.primary};
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AnnouncementHeader = styled.h3`
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.text.primary};
`;

export const AnnouncementDescription = styled.p`
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.text.primary};
`;

export const AnnouncementRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: ${(props) => props.theme.text.secondary};
`;

export const AnnouncementActions = styled.div`
  margin-top: 8px;
`;

export const DeleteAnnouncementButton = styled(Button)`
  color: #ce2c21;
  border: 1px solid #ce2c21;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${(props) => hexToRgba(props.theme.error, 0.1)};
  padding: 6px 12px;

  &:hover {
    background-color: ${(props) => hexToRgba(props.theme.error, 0.2)};
  }
`;

export const AddAnnouncementButton = styled(ModalButton)`
  align-self: flex-start;
`;

export const UploadBackupImageWrapper = styled.div<{ disabled: boolean }>`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${(props) => props.theme.text.primary};

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `}
`;

export const BackupImageSuccessMessage = styled.p`
  color: #548671;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const BackupImageInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const BackupImageInfo = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: ${(props) => props.theme.text.secondary};
`;
