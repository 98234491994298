import { DefaultTheme } from 'styled-components';

export const colors = {
  plusIcon: '#CCCCCC',
  error: '#bf0202',
  black: '#000000',
};

const LIGHT_THEME: DefaultTheme = {
  isDarkMode: false,
  error: colors.error,
  background: {
    primary: '#fff',
    secondary: '#cccccc',
    modal: '#fff',
    form: '#fff',
    error: '#fbf0f0',
    imageUploader: '#E7E7EB',
    dropdown: '#fff',
    button: '#282828',
    imageUploaderButton: '#282828',
    buttonHover: '#3C3C3C',
    buttonModal: '#282828',
    cardModal: 'transparent',
    cardModalHover: '#f5f5f5',
    cardModalDisabled: '#f5f5f5',
    plusButtonHover: '#f5f5f5',
  },
  text: {
    primary: '#000',
    secondary: '#828282',
    button: '#fff',
  },
  border: {
    primary: '#C2C2C2',
    form: '#C2C2C2',
  },
};

const DARK_THEME: DefaultTheme = {
  isDarkMode: true,
  error: colors.error,
  background: {
    primary: '#000000',
    secondary: '#222222',
    modal: '#282828',
    form: '#3C3C3C',
    error: '#52110D',
    imageUploader: '#646464',
    dropdown: '#505050',
    button: '#282828',
    imageUploaderButton: '#111111',
    buttonHover: '#3C3C3C',
    buttonModal: '#000000',
    cardModal: '#3C3C3C',
    cardModalHover: '#525252',
    cardModalDisabled: '#262626',
    plusButtonHover: '#2d2d2d',
  },
  text: {
    primary: '#ffffff',
    secondary: '#828282',
    button: '#ffffff',
  },
  border: {
    primary: '#5e5d5d',
    form: 'transparent',
  },
};

export const generateTheme = (isDarkMode: boolean) => (isDarkMode ? DARK_THEME : LIGHT_THEME);
