import { Calendar } from '@phosphor-icons/react';
import 'react-datepicker/dist/react-datepicker.css';

import * as S from './date-picker.styled';
import { Label } from '../label';

interface DatePickerProps {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  label?: string;
  placeholder?: string;
  error?: string;
}

export const DatePicker = ({ selected, onChange, label, error, placeholder }: DatePickerProps) => (
  <S.DatePickerWrapper>
    {label ? <Label>{label}</Label> : null}
    <S.DatePicker
      $error={!!error}
      showIcon
      icon={<Calendar />}
      placeholderText={placeholder}
      selected={selected}
      onChange={onChange}
    />
    {error && <S.Error>{error}</S.Error>}
  </S.DatePickerWrapper>
);
